
.subscription {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  color: #fff;
  padding: 30px;

  // background: linear-gradient(209.13deg, #6FEAE4 9.91%, #A08AF3 98.86%);
  border-radius: 4.8px;

  margin-bottom: 30px;

  .price {
    font-size: 24px;
    font-weight: bold;
  }
}
