.login-screen {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #13284f;

  .top-padding {
    padding-top: 5vh !important;
  }
  .logo {
    height: unset;
  }
}

@media screen and (min-width: 750px) {
  .noWrap {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 750px) {
  .info-login-form {
    padding: 2rem;
  }
}

.login-outbox {
  background: linear-gradient(to bottom, #68ead1 100%, #67c3e6 51%, #68bdf1 0%);
  border-radius: 15px;
  box-shadow: 0 0 18px 0 rgba(138, 225, 255, 0.25);
  position: relative;
  padding: 3px;
}

.info-login-form {
  background-color: rgb(9, 28, 57);
  margin: 0;
  border-radius: 15px;
  border: none;
  max-width: 90vw;

  .page-title {
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 2rem;
  }
}

.dark-input {
  border: none !important;
  background: #13284f;
  background-image: none !important;
  background-color: #13284f !important;
  color: inherit !important;
}

.dark-input:-internal-autofill-selected {
  border: none !important;
  background-image: none !important;
  background-color: #13284f !important;
  color: inherit !important;
}

.password_container_login {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  & > .input-wrapper > input {
    margin-bottom: 0px !important;
  }
}

.login-page-link {
  font-size: 14px;
}

.bar-divider {
  width: 100%;
  height: 1px;
  background: rgba(53, 97, 124, 0.5);
  margin-top: 1rem;
}

.login-btn {
  width: 100%;
  padding: 0.75rem 0;
  font-size: 1rem;
  min-height: 0;
  height: auto !important;
  background: #faf56a !important;
  color: #3c4045;
  transition: background 0.3s ease-in-out;
}

.login-btn:hover {
  background: #fdf74d !important;
}

.login-logo-container {
  display: flex;
  justify-self: center;
  margin-bottom: 2rem;
  cursor: pointer;
}

@import '../../theme/colors';

.name {
  display: flex;

  input {
    width: 100%;
  }

  input::placeholder {
    background: transparent !important;
  }
}

.footer_phone {
  background: #0b5287 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10rem;

  & p {
    font-size: 9px;
    font-family: 'AvenirNext';
    color: rgba(white, 0.5);
    margin: 5px 0 10px;
  }

  &_bar {
    height: 1px;
    width: 80vw;
    background: rgba(white, 0.5);
  }

  &_text {
    margin: 15px 0 !important;
    line-height: 13px;
    width: 80vw;
  }

  &_legal_links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 20px 0 !important;
    text-transform: uppercase;

    &_link {
      font-size: 11px;
      font-family: 'AvenirNext';
      text-transform: uppercase;
      text-decoration: none;
      color: rgba(white, 0.4);
      font-weight: 500;
    }
  }

  &_copyrights {
    margin-bottom: 30px !important;
  }
}

.progress {
  display: flex;
  height: 1.5rem;
  width: calc(100% - 50px);
  margin: auto;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    #548dc1 25%,
    transparent 25%,
    transparent 50%,
    #548dc1 50%,
    #548dc1 75%,
    transparent 75%,
    transparent
  );
  background-size: 2rem 2rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3279b7;
  transition: width 0.6s ease;
}

.password_container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  & > .input-wrapper > input {
    margin-bottom: 0px !important;
  }
}

.visibility-eye {
  position: absolute;
  right: 8%;
  font-size: 1.5rem !important;
  color: grey;
  cursor: pointer;
  transition: color 0.1s ease-in-out,
    fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;

  &:hover {
    color: rgb(68, 68, 68);
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@media screen and (min-width: 751px) {
  .footer_phone {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .footer_desktop {
    display: none;
  }
  .visibility-eye {
    font-size: 2rem !important;
  }
}
