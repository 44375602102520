.payment_loading_loader{
  width: 40px;
  height: 40px;
  border: 4px solid #216fb7;
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  position: relative;
  position: relative;
}

.payment_loading{
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}