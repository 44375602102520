.response-box-input {

  margin-bottom: 6px;
  border: 2px solid #B77BFF;
  border-radius: 6px;

  cursor: pointer;

  textarea {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 20px;
    color: #fff;
    border: none;
  }

}