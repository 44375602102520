@import '../../theme/colors';

.paiement {
  background: $blueGradientUp;

  min-height: 100vh;
  padding: 40px;

  .section-0 {
    text-align: center;
    height: 200px;

    .logo {
      max-width: 175px;
      margin: auto;
    }
  }

  &_terms {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-align: left;
    width: calc(100% - 20px);

    &_check {
      width: unset !important;
      height: unset !important;
      margin-bottom: 0 !important;
      margin-right: 7px !important;
    }

    &_text {
      font-family: 'Roboto';
      font-size: 11px;
      color: #434343;
    }

    &_link {
      color: #4282c3 !important;
    }
  }

  .section-1 {
    width: 75%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: auto;

    .infos {
      color: white;
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      // padding-top: 40px;

      h1 {
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 25px;
        font-family: 'Roboto';
        font-weight: 900;
        text-transform: uppercase;
        max-width: 80%;
        text-align: center;
      }

      h2 {
        display: flex;
        align-items: center;

        .prix_base {
          text-decoration: line-through #e25e5e;
          margin-right: 10px;
        }

        .tick {
          width: 76px;
          max-height: 76px;
        }

        .prix_reduit {
          color: #e8df64;
          font-family: 'Roboto';
          font-weight: 700;
          margin-left: 10px;
          margin: auto;
          min-height: 72px;
          display: flex;
        }

        .old_price,
        .new_price {
          color: #e8df64;
          font-family: 'Roboto';
          font-weight: 700;
          margin: auto;
          min-height: 72px;
          text-decoration: none;
          position: relative;
        }

        .old_price::before {
          content: '';
          width: 120%;
          position: absolute;
          right: -15px;
          top: calc(40% - 1.5px);
          border-bottom: 4px solid rgb(255, 0, 0);
          transform: rotate(-15deg);
        }

        font-size: 60px;
      }

      p {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 18px;
        margin: 20px 0 40px 0;
        line-height: 28px;
      }

      .ticks {
        font-family: 'Roboto';
        .step {
          display: flex;
          font-size: 20px;
          margin-bottom: 12px;
        }

        .tick {
          width: 29px;
          margin-right: 12px;
          max-height: 29px;
        }
      }
    }

    .formulaire {
      width: 50%;
      min-width: 500px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .top-head {
        height: 65px;
        font-size: 20px;
        color: white;
        background: #183552;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        font-family: 'Roboto';
        font-family: 'OpenSans';
        font-weight: bold;
      }

      form {
        max-width: 400px;
        background: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        border-radius: 8px;

        .form_paiement_title {
          font-family: 'Roboto';
          color: #434343;
          font-size: 13px;
          line-height: 30px;
          text-transform: uppercase;
          text-align: left;
          width: 100%;
          margin: 13px 0 12px 20px;
        }

        .global-btn {
          margin: 20px auto 15px !important;
          // background: #5cc33c;
          background: #32bea6;
          color: white;
          box-shadow: 0px 3px 0px #0c7961;
          border: none;
          // box-shadow: 1px 4px 2px #4a9d31;
          width: calc(100% - 20px);

          .button-title {
            font-family: 'Roboto';
            font-weight: 700;
            line-height: 27px;
          }
        }

        .form-tabs {
          display: flex;
          justify-content: space-between;
          padding: 15px 15px 0;

          .form-tab {
            display: flex;
            margin: auto;

            .number {
              color: #646464;
              font-size: 25px;
              align-self: center;
              margin-right: 10px;
            }

            .tab-title {
              color: #3e90db;
              line-height: 26px;
              font-size: 15px;
              text-transform: uppercase;
              text-align: left;
              font-family: 'Roboto';
              font-weight: 700;

              .tab-sub {
                font-size: 9px;
                line-height: 10px;
                color: #333;
                text-transform: none;
                font-family: 'Roboto';
                font-weight: 400;
              }
            }
          }
        }

        .fields {
          padding: 0px 30px 0px;
          height: 100%;
          align-items: center;
          display: flex;
          flex-direction: column;

          .name {
            display: flex;

            input {
              width: 100%;
            }

            input::placeholder {
              background: transparent !important;
            }
          }
        }
      }

      label {
        color: #6b7c93;
        font-weight: 300;
        letter-spacing: 0.025em;
        margin-top: 16px;
        display: block;
      }

      button {
        white-space: nowrap;
        border: 0;
        outline: 0;
        display: inline-block;
        height: 36px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
          0 1px 3px rgba(0, 0, 0, 0.08);
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: #6772e5;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        margin-top: 10px;
      }

      button:hover {
        color: #fff;
        cursor: pointer;
        background-color: #7795f8;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
          0 3px 6px rgba(0, 0, 0, 0.08);
      }

      button[disabled] {
        opacity: 0.6;
      }

      input {
        font-family: 'Roboto';
        font-weight: 300;
        display: block;
        border: none;
        font-size: 14px;
        margin: 0px 0 10px 0;
        max-width: 100%;
        padding: 10px 14px;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
          rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border-radius: 0px;
        border: solid 1.5px #ccc;
        background: white;
        color: #424770;
        letter-spacing: 0.025em;
        width: 500px;
        border-radius: 4px;
        height: 46px;
      }

      input[type='password'] {
        margin-bottom: 20px;
      }

      input::placeholder {
        color: #333;
        font-weight: 400;
        background: transparent;
      }

      .result,
      .error {
        font-size: 14px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 0px;
      }

      .error {
        color: #e4584c;
      }

      .result {
        color: #666ee8;
      }

      .credit_card {
        background: #f2f2f2;
        border: solid 2px #ccc;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 355px;
        margin: 40px auto 0px auto;
        padding: 6px 23px 23px 23px;

        .cc_container {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }

        .badge_stripe {
          max-height: 30px;
          max-width: 30%;
          margin: 0 0 5px auto;
        }
      }

      .badges {
        max-height: 50px;
        max-width: 60%;
        margin: 10px auto;
      }

      /*
    The StripeElement class is applied to the Element container by default.
    More info: https://stripe.com/docs/stripe-js/reference#element-options
    */

      .StripeElement,
      .StripeElementIdeal {
        display: block;
        margin: 0px 0 5px 0;
        max-width: 500px;
        padding: 10px 14px;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
          rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border-radius: 4px;
        background: white;
      }

      .StripeElement--focus,
      .StripeElementIdeal--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
          rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
      }

      .StripeElement.loading {
        height: 41.6px;
        opacity: 0.6;
      }

      .StripeElementIdeal {
        padding: 0;
      }

      .payment-screen {
        .content-box {
          height: 100%;
        }

        .payment-title {
          text-align: center;
          font-family: 'AvenirNext-HeavyItalic', 'Helvetica Neue', sans-serif;

          font-size: 27px;
          margin-bottom: 40px;
        }

        .payment-intro {
          font-family: 'AvenirNext-Italic', 'Helvetica Neue', sans-serif;
          margin-bottom: 40px;
          text-align: center;
          font-size: 18px;
          line-height: 18px;
        }

        .no-engage {
          // margin-top: 59%;
        }

        .confirm-box {
          border: 10px solid #979797;

          background: rgba(255, 255, 255, 0.28);

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding-bottom: 40px;
          height: 407px;
        }

        .box-content {
          font-family: 'AvenirNext-HeavyItalic', 'Helvetica Neue', sans-serif;
          width: 502px;
          height: 121px;
          font-size: 37.53px;
          font-weight: 400;
          line-height: 50px;
          text-align: center;
        }

        .btns {
          font-family: 'AvenirNext-DemiBold', 'Helvetica Neue', sans-serif;
          font-size: 23px;
          display: flex;
          flex-direction: row;
          align-items: center;

          div {
            margin: 20px;
          }
        }
      }

      .mentions_under-btn {
        color: #9a9a9a !important;
        font-size: 0.7rem;
        margin-top: 13px;
        transition: color 0.2s ease-in-out;
        line-height: 20px;

        & a {
          color: #9a9a9a !important;
          text-transform: none;
          text-decoration: none;

          &:hover {
            color: #757575 !important;
          }
        }
      }

      @media screen and (max-width: 750px) {
        .payment-screen .no-engage {
          margin-top: 20% !important;
        }

        .payment-title {
          font-size: 16px !important;
        }

        .payment-intro {
          margin-bottom: 0px !important;

          su .content-box {
            margin: 0 !important;
            padding-top: 120px !important;
          }

          .confirm-box {
            height: 300px !important;
          }

          .box-content {
            font-family: 'AvenirNext-HeavyItalic', 'Helvetica Neue', sans-serif;
            width: unset !important;
            height: 121px;
            font-size: 20px !important;
            font-weight: 400;
            line-height: 50px;
            text-align: center;
          }

          .btns {
            font-size: 18px !important;
          }
        }
      }
    }

    .connect-link {
      display: block;
      width: 100%;
      color: rgba(white, 0.8);
      font-family: 'Roboto';
      font-weight: 400;
      text-decoration: underline;
      font-size: 13px;
      margin: auto;
      margin-top: 20px;
      text-align: center;
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      &:hover {
        color: rgba(white, 1);
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .paiement {
      padding: 40px 120px;
    }
  }

  @media screen and (max-width: 1200px) {
    .section-1 {
      width: 90% !important;
    }

    .formulaire {
      min-width: 400px !important;
      width: 45% !important;
    }

    .top-head,
    form {
      margin: 0 auto !important;
    }

    .infos {
      width: 55% !important;
    }
  }

  @media screen and (max-width: 1200px) {
    .formulaire {
      min-width: 250px !important;
      width: 50% !important;
    }
  }

  @media screen and (max-width: 750px) {
    padding: 40px 0;

    .section-0 {
      height: 150px;
    }

    .section-1 {
      width: 100%;
      flex-flow: column wrap !important;
      align-items: center;

      .infos {
        width: 100% !important;
        text-align: center;
        margin-bottom: 20px;

        h1 {
          font-size: 27px;
          text-align: center;
          max-width: 90%;
        }

        h2 {
          justify-content: center;
        }

        p {
          font-size: 17px;
        }

        .ticks {
          .step {
            text-align: left;
          }
        }

        width: 100%;
      }

      .formulaire {
        width: 100% !important;
        margin-top: 20px;

        form {
          width: 100% !important;

          .global-btn {
            width: 90% !important;
          }

          .form-tabs {
            padding: 20px 15px 2px;
            width: 100%;
            margin: 0px auto auto auo;

            .form-tab {
              align-items: top;

              .tab-title {
                font-size: 13px;

                .number {
                  font-size: 34px;
                  margin-right: 5px;
                }
              }

              width: 45%;
              margin: 0;
            }
          }

          .fields {
            .name {
              input {
                /* width: 100%; */
                width: 95% !important;
              }
            }
          }
        }
      }

      .button-title {
        font-size: 15px;
      }

      .sub {
        font-size: 11px;
      }

      div.input-wrapper:nth-child(1) > input:nth-child(1) {
        float: left;
      }

      .name > div:nth-child(2) > input:nth-child(1) {
        float: right;
      }
    }
  }

  .global-btn.linear-btn.big-btn {
    height: 65px !important;
  }
}

.footer_desktop {
  background: #0b5287 !important;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  font-family: 'OpenSans';
  font-weight: 500;
  color: rgba(white, 0.6);

  &_top,
  &_bottom {
    display: flex !important;
    flex-flow: row nowrap;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-around;
  }

  &_stars {
    height: 10px;
    width: 52px;
    margin-right: 8px;
  }

  &_legal {
    text-decoration: none !important;
    color: rgba($color: white, $alpha: 0.6) !important;
  }

  &_grey:link {
    text-decoration: none !important;
    color: rgb(136, 136, 136) !important;
  }

  & a:hover {
    color: rgba($color: white, $alpha: 0.4) !important;
  }

  & .footer_desktop_avis {
    text-transform: none !important;
  }
}

.footer_phone {
  background: #0b5287 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10rem;

  & p {
    font-size: 9px;
    font-family: 'AvenirNext';
    color: rgba(white, 0.5);
    margin: 5px 0 10px;
  }

  &_bar {
    height: 1px;
    width: 80vw;
    background: rgba(white, 0.5);
  }

  &_text {
    margin: 15px 0 !important;
    line-height: 13px;
    width: 80vw;
  }

  &_legal_links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 20px 0 !important;
    text-transform: uppercase;

    &_link {
      font-size: 11px;
      font-family: 'AvenirNext';
      text-transform: uppercase;
      text-decoration: none;
      color: rgba(white, 0.4);
      font-weight: 500;
    }
  }

  &_copyrights {
    margin-bottom: 30px !important;
  }
}

.progress {
  display: flex;
  height: 1.5rem;
  width: calc(100% - 50px);
  margin: auto;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    #548dc1 25%,
    transparent 25%,
    transparent 50%,
    #548dc1 50%,
    #548dc1 75%,
    transparent 75%,
    transparent
  );
  background-size: 2rem 2rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3279b7;
  transition: width 0.6s ease;
}

.password_container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  & > .input-wrapper > input {
    margin-bottom: 0px !important;
  }
}

.visibility-eye {
  position: absolute;
  right: 8%;
  font-size: 1.5rem !important;
  color: grey;
  cursor: pointer;
  transition: color 0.1s ease-in-out,
    fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;

  &:hover {
    color: rgb(68, 68, 68);
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@media screen and (min-width: 751px) {
  .footer_phone {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .footer_desktop {
    display: none;
  }
  .visibility-eye {
    font-size: 2rem !important;
  }
}
