@import "../../../theme/colors";

.percentage-bar {

  padding  : 10px 30px;
  animation: 2s ease-in-out forwards 50;

  .titles {
    font-size    : 14px;
    margin-bottom: 6px;
  }

  $height: 40px;

  .very-small {
    height     : 20px;
    line-height: 20px;
  }

  .small-bar {
    height: 40px;

    // line-height: 40px;
    .left,
    .third,
    .right {
      height     : 44px !important;
      padding-top: 14px !important;
      font-size  : 13px !important;
    }
  }

  .large {
    height        : 88px;
    // line-height: 88px;
  }

  .content {
    $radius: 8px;

    background   : #8ab7ce;
    // height: $height;
    color        : #fff;
    border-radius: $radius;
    overflow     : hidden;
    position     : relative;
    font-size    : 23px;
    font-family  : "AvenirNext-Bold";

    .subp {
      font-size: 14px;
      color    : #eee;
    }

    .left {
      // line-height: $height;
      width                         : 0%;
      opacity                       : 0.4;
      position                      : relative;
      top                           : 0;
      bottom                        : 0;
      left                          : 0;
      // padding-left               : 12px;
      border-radius                 : $radius;
      background                    : $rteal;
      color                         : #fff;
      -webkit-animation             : progressBar 3s ease-in-out;
      -webkit-animation-fill-mode   : both;
      -moz-animation                : progressBar 3s ease-in-out;
      -moz-animation-fill-mode      : both;
      // background: linear-gradient(214.28deg, #4A26AB -36.35%, #D76788 90.73%);
      animation                     : fade-in-text 2.2s 0.1s forwards;
      // background-color: #03AB70;
      transition                    : width ease-in 1s;
      -webkit-transition            : width ease-in 1s;
      -moz-transition               : width ease-in 1s;
      -o-transition                 : width ease-in 1s;
      height                        : 88px;
      padding-top                   : 22px;
    }

    .right,
    .third {
      // line-height: $height;

      position            : relative;
      top                 : 0;
      bottom              : 0;
      right               : 0;
      // padding-right    : 12px;
      -webkit-animation   : fade-in-text 2.2s 1s forwards;
      -moz-animation      : fade-in-text 2.2s 1s forwards;
      animation           : fade-in-text 2.2s 1s forwards;
      font-size           : 12px;

      .subp {
        font-size: 10px;

      }
    }

    // .third {
    //   // line-height: $height;

    //   position         : relative;
    //   top              : 0;
    //   bottom           : 0;
    //   right            : 0;
    //   padding-right    : 12px;
    //   -webkit-animation: fade-in-text 2.2s 1s forwards;
    //   -moz-animation   : fade-in-text 2.2s 1s forwards;
    //   animation        : fade-in-text 2.2s 1ss forwards;
    // }
  }



  @media screen and (max-width: 750px) {
    .content {
      border-radius: 1px;
      font-size    : 13px;
      height       : 40px;

      .subp {
        font-size: 7px;
      }

      .left,
      .right,
      .third {
        border-radius  : 0px;
        height         : 100%;
        padding-top    : 0px !important;
        display        : flex;
        flex-flow      : row nowrap;
        align-items    : center;
        justify-content: center;
      }

      .titles {
        font-size: 12px;
      }
    }

    .very-small {
      height     : 25px !important;
      line-height: 20px !important;
      padding-top: unset !important;

      .left {
        padding-top: 1px !important;
      }

      .right,
      .third {
        border-radius: 0px;
        height       : unset;
        padding-top  : 2px !important;
      }
    }
  }
}