* {
    box-sizing: border-box;
  }
  .c100,
  .slice,
  .circle,
  .warp_first_half,
  .first_half,
  .second_half {
    height: 100px;
    width: 100px;
  }
  .slice,
  .c100 {
    position: relative;
  }
  .c100 {
    display: inline-block;
  }
  .circle,
  .first_half,
  .second_half {
    border: 8px solid #2dfcc9;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .warp_first_half {
    overflow: hidden;
    left: 50px;
    position: absolute;
    transform: rotate(180deg);
    width: 50px;
  }
  .first_half {
    clip: rect(0px, 50px, 100px, 0px);
  }
  [data-p="51"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="51"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_51;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_51 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(183.6deg);
    }
  }
  @-webkit-keyframes second_half_51 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(183.6deg);
    }
  }
  @-o-keyframes second_half_51 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(183.6deg);
    }
  }
  @keyframes second_half_51 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(183.6deg);
    }
  }
  [data-p="52"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="52"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_52;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_52 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(187.20000000000002deg);
    }
  }
  @-webkit-keyframes second_half_52 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(187.20000000000002deg);
    }
  }
  @-o-keyframes second_half_52 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(187.20000000000002deg);
    }
  }
  @keyframes second_half_52 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(187.20000000000002deg);
    }
  }
  [data-p="53"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="53"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_53;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_53 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(190.8deg);
    }
  }
  @-webkit-keyframes second_half_53 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(190.8deg);
    }
  }
  @-o-keyframes second_half_53 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(190.8deg);
    }
  }
  @keyframes second_half_53 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(190.8deg);
    }
  }
  [data-p="54"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="54"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_54;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_54 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(194.4deg);
    }
  }
  @-webkit-keyframes second_half_54 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(194.4deg);
    }
  }
  @-o-keyframes second_half_54 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(194.4deg);
    }
  }
  @keyframes second_half_54 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(194.4deg);
    }
  }
  [data-p="55"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="55"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_55;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_55 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(198deg);
    }
  }
  @-webkit-keyframes second_half_55 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(198deg);
    }
  }
  @-o-keyframes second_half_55 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(198deg);
    }
  }
  @keyframes second_half_55 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(198deg);
    }
  }
  [data-p="56"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="56"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_56;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_56 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(201.6deg);
    }
  }
  @-webkit-keyframes second_half_56 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(201.6deg);
    }
  }
  @-o-keyframes second_half_56 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(201.6deg);
    }
  }
  @keyframes second_half_56 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(201.6deg);
    }
  }
  [data-p="57"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="57"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_57;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_57 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(205.20000000000002deg);
    }
  }
  @-webkit-keyframes second_half_57 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(205.20000000000002deg);
    }
  }
  @-o-keyframes second_half_57 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(205.20000000000002deg);
    }
  }
  @keyframes second_half_57 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(205.20000000000002deg);
    }
  }
  [data-p="58"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="58"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_58;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_58 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(208.8deg);
    }
  }
  @-webkit-keyframes second_half_58 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(208.8deg);
    }
  }
  @-o-keyframes second_half_58 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(208.8deg);
    }
  }
  @keyframes second_half_58 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(208.8deg);
    }
  }
  [data-p="59"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="59"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_59;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_59 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(212.4deg);
    }
  }
  @-webkit-keyframes second_half_59 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(212.4deg);
    }
  }
  @-o-keyframes second_half_59 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(212.4deg);
    }
  }
  @keyframes second_half_59 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(212.4deg);
    }
  }
  [data-p="60"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="60"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_60;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_60 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(216deg);
    }
  }
  @-webkit-keyframes second_half_60 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(216deg);
    }
  }
  @-o-keyframes second_half_60 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(216deg);
    }
  }
  @keyframes second_half_60 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(216deg);
    }
  }
  [data-p="61"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="61"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_61;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_61 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(219.6deg);
    }
  }
  @-webkit-keyframes second_half_61 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(219.6deg);
    }
  }
  @-o-keyframes second_half_61 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(219.6deg);
    }
  }
  @keyframes second_half_61 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(219.6deg);
    }
  }
  [data-p="62"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="62"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_62;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_62 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(223.20000000000002deg);
    }
  }
  @-webkit-keyframes second_half_62 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(223.20000000000002deg);
    }
  }
  @-o-keyframes second_half_62 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(223.20000000000002deg);
    }
  }
  @keyframes second_half_62 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(223.20000000000002deg);
    }
  }
  [data-p="63"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="63"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_63;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_63 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(226.8deg);
    }
  }
  @-webkit-keyframes second_half_63 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(226.8deg);
    }
  }
  @-o-keyframes second_half_63 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(226.8deg);
    }
  }
  @keyframes second_half_63 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(226.8deg);
    }
  }
  [data-p="64"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="64"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_64;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_64 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(230.4deg);
    }
  }
  @-webkit-keyframes second_half_64 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(230.4deg);
    }
  }
  @-o-keyframes second_half_64 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(230.4deg);
    }
  }
  @keyframes second_half_64 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(230.4deg);
    }
  }
  [data-p="65"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="65"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_65;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_65 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(234deg);
    }
  }
  @-webkit-keyframes second_half_65 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(234deg);
    }
  }
  @-o-keyframes second_half_65 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(234deg);
    }
  }
  @keyframes second_half_65 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(234deg);
    }
  }
  [data-p="66"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="66"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_66;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_66 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(237.6deg);
    }
  }
  @-webkit-keyframes second_half_66 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(237.6deg);
    }
  }
  @-o-keyframes second_half_66 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(237.6deg);
    }
  }
  @keyframes second_half_66 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(237.6deg);
    }
  }
  [data-p="67"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="67"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_67;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_67 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(241.20000000000002deg);
    }
  }
  @-webkit-keyframes second_half_67 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(241.20000000000002deg);
    }
  }
  @-o-keyframes second_half_67 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(241.20000000000002deg);
    }
  }
  @keyframes second_half_67 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(241.20000000000002deg);
    }
  }
  [data-p="68"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="68"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_68;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_68 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(244.8deg);
    }
  }
  @-webkit-keyframes second_half_68 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(244.8deg);
    }
  }
  @-o-keyframes second_half_68 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(244.8deg);
    }
  }
  @keyframes second_half_68 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(244.8deg);
    }
  }
  [data-p="69"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="69"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_69;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_69 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(248.4deg);
    }
  }
  @-webkit-keyframes second_half_69 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(248.4deg);
    }
  }
  @-o-keyframes second_half_69 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(248.4deg);
    }
  }
  @keyframes second_half_69 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(248.4deg);
    }
  }
  [data-p="70"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="70"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_70;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_70 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(252deg);
    }
  }
  @-webkit-keyframes second_half_70 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(252deg);
    }
  }
  @-o-keyframes second_half_70 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(252deg);
    }
  }
  @keyframes second_half_70 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(252deg);
    }
  }
  [data-p="71"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="71"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_71;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_71 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(255.6deg);
    }
  }
  @-webkit-keyframes second_half_71 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(255.6deg);
    }
  }
  @-o-keyframes second_half_71 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(255.6deg);
    }
  }
  @keyframes second_half_71 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(255.6deg);
    }
  }
  [data-p="72"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="72"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_72;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_72 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(259.2deg);
    }
  }
  @-webkit-keyframes second_half_72 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(259.2deg);
    }
  }
  @-o-keyframes second_half_72 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(259.2deg);
    }
  }
  @keyframes second_half_72 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(259.2deg);
    }
  }
  [data-p="73"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="73"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_73;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_73 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(262.8deg);
    }
  }
  @-webkit-keyframes second_half_73 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(262.8deg);
    }
  }
  @-o-keyframes second_half_73 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(262.8deg);
    }
  }
  @keyframes second_half_73 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(262.8deg);
    }
  }
  [data-p="74"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="74"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_74;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_74 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(266.40000000000003deg);
    }
  }
  @-webkit-keyframes second_half_74 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(266.40000000000003deg);
    }
  }
  @-o-keyframes second_half_74 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(266.40000000000003deg);
    }
  }
  @keyframes second_half_74 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(266.40000000000003deg);
    }
  }
  [data-p="75"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="75"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_75;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_75 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(270deg);
    }
  }
  @-webkit-keyframes second_half_75 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(270deg);
    }
  }
  @-o-keyframes second_half_75 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(270deg);
    }
  }
  @keyframes second_half_75 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(270deg);
    }
  }
  [data-p="76"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="76"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_76;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_76 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(273.6deg);
    }
  }
  @-webkit-keyframes second_half_76 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(273.6deg);
    }
  }
  @-o-keyframes second_half_76 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(273.6deg);
    }
  }
  @keyframes second_half_76 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(273.6deg);
    }
  }
  [data-p="77"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="77"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_77;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_77 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(277.2deg);
    }
  }
  @-webkit-keyframes second_half_77 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(277.2deg);
    }
  }
  @-o-keyframes second_half_77 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(277.2deg);
    }
  }
  @keyframes second_half_77 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(277.2deg);
    }
  }
  [data-p="78"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="78"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_78;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_78 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(280.8deg);
    }
  }
  @-webkit-keyframes second_half_78 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(280.8deg);
    }
  }
  @-o-keyframes second_half_78 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(280.8deg);
    }
  }
  @keyframes second_half_78 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(280.8deg);
    }
  }
  [data-p="79"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="79"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_79;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_79 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(284.40000000000003deg);
    }
  }
  @-webkit-keyframes second_half_79 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(284.40000000000003deg);
    }
  }
  @-o-keyframes second_half_79 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(284.40000000000003deg);
    }
  }
  @keyframes second_half_79 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(284.40000000000003deg);
    }
  }
  [data-p="80"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="80"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_80;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_80 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(288deg);
    }
  }
  @-webkit-keyframes second_half_80 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(288deg);
    }
  }
  @-o-keyframes second_half_80 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(288deg);
    }
  }
  @keyframes second_half_80 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(288deg);
    }
  }
  [data-p="81"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="81"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_81;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_81 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(291.6deg);
    }
  }
  @-webkit-keyframes second_half_81 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(291.6deg);
    }
  }
  @-o-keyframes second_half_81 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(291.6deg);
    }
  }
  @keyframes second_half_81 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(291.6deg);
    }
  }
  [data-p="82"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="82"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_82;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_82 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(295.2deg);
    }
  }
  @-webkit-keyframes second_half_82 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(295.2deg);
    }
  }
  @-o-keyframes second_half_82 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(295.2deg);
    }
  }
  @keyframes second_half_82 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(295.2deg);
    }
  }
  [data-p="83"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="83"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_83;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_83 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(298.8deg);
    }
  }
  @-webkit-keyframes second_half_83 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(298.8deg);
    }
  }
  @-o-keyframes second_half_83 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(298.8deg);
    }
  }
  @keyframes second_half_83 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(298.8deg);
    }
  }
  [data-p="84"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="84"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_84;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_84 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(302.40000000000003deg);
    }
  }
  @-webkit-keyframes second_half_84 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(302.40000000000003deg);
    }
  }
  @-o-keyframes second_half_84 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(302.40000000000003deg);
    }
  }
  @keyframes second_half_84 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(302.40000000000003deg);
    }
  }
  [data-p="85"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="85"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_85;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_85 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(306deg);
    }
  }
  @-webkit-keyframes second_half_85 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(306deg);
    }
  }
  @-o-keyframes second_half_85 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(306deg);
    }
  }
  @keyframes second_half_85 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(306deg);
    }
  }
  [data-p="86"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="86"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_86;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_86 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(309.6deg);
    }
  }
  @-webkit-keyframes second_half_86 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(309.6deg);
    }
  }
  @-o-keyframes second_half_86 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(309.6deg);
    }
  }
  @keyframes second_half_86 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(309.6deg);
    }
  }
  [data-p="87"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="87"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_87;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_87 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(313.2deg);
    }
  }
  @-webkit-keyframes second_half_87 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(313.2deg);
    }
  }
  @-o-keyframes second_half_87 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(313.2deg);
    }
  }
  @keyframes second_half_87 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(313.2deg);
    }
  }
  [data-p="88"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="88"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_88;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_88 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(316.8deg);
    }
  }
  @-webkit-keyframes second_half_88 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(316.8deg);
    }
  }
  @-o-keyframes second_half_88 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(316.8deg);
    }
  }
  @keyframes second_half_88 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(316.8deg);
    }
  }
  [data-p="89"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="89"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_89;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_89 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(320.40000000000003deg);
    }
  }
  @-webkit-keyframes second_half_89 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(320.40000000000003deg);
    }
  }
  @-o-keyframes second_half_89 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(320.40000000000003deg);
    }
  }
  @keyframes second_half_89 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(320.40000000000003deg);
    }
  }
  [data-p="90"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="90"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_90;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_90 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(324deg);
    }
  }
  @-webkit-keyframes second_half_90 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(324deg);
    }
  }
  @-o-keyframes second_half_90 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(324deg);
    }
  }
  @keyframes second_half_90 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(324deg);
    }
  }
  [data-p="91"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="91"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_91;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_91 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(327.6deg);
    }
  }
  @-webkit-keyframes second_half_91 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(327.6deg);
    }
  }
  @-o-keyframes second_half_91 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(327.6deg);
    }
  }
  @keyframes second_half_91 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(327.6deg);
    }
  }
  [data-p="92"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="92"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_92;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_92 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(331.2deg);
    }
  }
  @-webkit-keyframes second_half_92 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(331.2deg);
    }
  }
  @-o-keyframes second_half_92 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(331.2deg);
    }
  }
  @keyframes second_half_92 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(331.2deg);
    }
  }
  [data-p="93"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="93"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_93;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_93 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(334.8deg);
    }
  }
  @-webkit-keyframes second_half_93 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(334.8deg);
    }
  }
  @-o-keyframes second_half_93 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(334.8deg);
    }
  }
  @keyframes second_half_93 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(334.8deg);
    }
  }
  [data-p="94"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="94"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_94;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_94 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(338.40000000000003deg);
    }
  }
  @-webkit-keyframes second_half_94 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(338.40000000000003deg);
    }
  }
  @-o-keyframes second_half_94 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(338.40000000000003deg);
    }
  }
  @keyframes second_half_94 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(338.40000000000003deg);
    }
  }
  [data-p="95"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="95"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_95;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_95 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(342deg);
    }
  }
  @-webkit-keyframes second_half_95 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(342deg);
    }
  }
  @-o-keyframes second_half_95 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(342deg);
    }
  }
  @keyframes second_half_95 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(342deg);
    }
  }
  [data-p="96"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="96"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_96;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_96 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(345.6deg);
    }
  }
  @-webkit-keyframes second_half_96 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(345.6deg);
    }
  }
  @-o-keyframes second_half_96 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(345.6deg);
    }
  }
  @keyframes second_half_96 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(345.6deg);
    }
  }
  [data-p="97"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="97"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_97;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_97 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(349.2deg);
    }
  }
  @-webkit-keyframes second_half_97 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(349.2deg);
    }
  }
  @-o-keyframes second_half_97 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(349.2deg);
    }
  }
  @keyframes second_half_97 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(349.2deg);
    }
  }
  [data-p="98"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="98"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_98;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_98 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(352.8deg);
    }
  }
  @-webkit-keyframes second_half_98 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(352.8deg);
    }
  }
  @-o-keyframes second_half_98 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(352.8deg);
    }
  }
  @keyframes second_half_98 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(352.8deg);
    }
  }
  [data-p="99"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="99"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_99;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_99 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(356.40000000000003deg);
    }
  }
  @-webkit-keyframes second_half_99 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(356.40000000000003deg);
    }
  }
  @-o-keyframes second_half_99 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(356.40000000000003deg);
    }
  }
  @keyframes second_half_99 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(356.40000000000003deg);
    }
  }
  [data-p="100"] .first_half {
    animation: 0.5s ease-in forwards first_half;
  }
  [data-p="100"] .second_half {
    opacity: 0;
    clip: rect(0px, 50px, 100px, 0px);
    animation: 0.5s ease-out 0.5s forwards second_half_100;
  }
  @-moz-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes first_half {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-moz-keyframes second_half_100 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes second_half_100 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
  @-o-keyframes second_half_100 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
  @keyframes second_half_100 {
    0% {
      opacity: 0;
      transform: rotate(180deg);
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
    }
  }
  [data-p="1"] .second_half {
    opacity: 0;
  }
  [data-p="1"] .first_half {
    animation: 1s ease-in-out forwards first_half_1;
  }
  @-moz-keyframes first_half_1 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-176.4deg);
    }
  }
  @-webkit-keyframes first_half_1 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-176.4deg);
    }
  }
  @-o-keyframes first_half_1 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-176.4deg);
    }
  }
  @keyframes first_half_1 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-176.4deg);
    }
  }
  [data-p="2"] .second_half {
    opacity: 0;
  }
  [data-p="2"] .first_half {
    animation: 1s ease-in-out forwards first_half_2;
  }
  @-moz-keyframes first_half_2 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-172.8deg);
    }
  }
  @-webkit-keyframes first_half_2 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-172.8deg);
    }
  }
  @-o-keyframes first_half_2 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-172.8deg);
    }
  }
  @keyframes first_half_2 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-172.8deg);
    }
  }
  [data-p="3"] .second_half {
    opacity: 0;
  }
  [data-p="3"] .first_half {
    animation: 1s ease-in-out forwards first_half_3;
  }
  @-moz-keyframes first_half_3 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-169.2deg);
    }
  }
  @-webkit-keyframes first_half_3 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-169.2deg);
    }
  }
  @-o-keyframes first_half_3 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-169.2deg);
    }
  }
  @keyframes first_half_3 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-169.2deg);
    }
  }
  [data-p="4"] .second_half {
    opacity: 0;
  }
  [data-p="4"] .first_half {
    animation: 1s ease-in-out forwards first_half_4;
  }
  @-moz-keyframes first_half_4 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-165.6deg);
    }
  }
  @-webkit-keyframes first_half_4 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-165.6deg);
    }
  }
  @-o-keyframes first_half_4 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-165.6deg);
    }
  }
  @keyframes first_half_4 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-165.6deg);
    }
  }
  [data-p="5"] .second_half {
    opacity: 0;
  }
  [data-p="5"] .first_half {
    animation: 1s ease-in-out forwards first_half_5;
  }
  @-moz-keyframes first_half_5 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-162deg);
    }
  }
  @-webkit-keyframes first_half_5 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-162deg);
    }
  }
  @-o-keyframes first_half_5 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-162deg);
    }
  }
  @keyframes first_half_5 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-162deg);
    }
  }
  [data-p="6"] .second_half {
    opacity: 0;
  }
  [data-p="6"] .first_half {
    animation: 1s ease-in-out forwards first_half_6;
  }
  @-moz-keyframes first_half_6 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-158.4deg);
    }
  }
  @-webkit-keyframes first_half_6 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-158.4deg);
    }
  }
  @-o-keyframes first_half_6 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-158.4deg);
    }
  }
  @keyframes first_half_6 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-158.4deg);
    }
  }
  [data-p="7"] .second_half {
    opacity: 0;
  }
  [data-p="7"] .first_half {
    animation: 1s ease-in-out forwards first_half_7;
  }
  @-moz-keyframes first_half_7 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-154.8deg);
    }
  }
  @-webkit-keyframes first_half_7 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-154.8deg);
    }
  }
  @-o-keyframes first_half_7 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-154.8deg);
    }
  }
  @keyframes first_half_7 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-154.8deg);
    }
  }
  [data-p="8"] .second_half {
    opacity: 0;
  }
  [data-p="8"] .first_half {
    animation: 1s ease-in-out forwards first_half_8;
  }
  @-moz-keyframes first_half_8 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-151.2deg);
    }
  }
  @-webkit-keyframes first_half_8 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-151.2deg);
    }
  }
  @-o-keyframes first_half_8 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-151.2deg);
    }
  }
  @keyframes first_half_8 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-151.2deg);
    }
  }
  [data-p="9"] .second_half {
    opacity: 0;
  }
  [data-p="9"] .first_half {
    animation: 1s ease-in-out forwards first_half_9;
  }
  @-moz-keyframes first_half_9 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-147.6deg);
    }
  }
  @-webkit-keyframes first_half_9 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-147.6deg);
    }
  }
  @-o-keyframes first_half_9 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-147.6deg);
    }
  }
  @keyframes first_half_9 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-147.6deg);
    }
  }
  [data-p="10"] .second_half {
    opacity: 0;
  }
  [data-p="10"] .first_half {
    animation: 1s ease-in-out forwards first_half_10;
  }
  @-moz-keyframes first_half_10 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-144deg);
    }
  }
  @-webkit-keyframes first_half_10 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-144deg);
    }
  }
  @-o-keyframes first_half_10 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-144deg);
    }
  }
  @keyframes first_half_10 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-144deg);
    }
  }
  [data-p="11"] .second_half {
    opacity: 0;
  }
  [data-p="11"] .first_half {
    animation: 1s ease-in-out forwards first_half_11;
  }
  @-moz-keyframes first_half_11 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-140.4deg);
    }
  }
  @-webkit-keyframes first_half_11 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-140.4deg);
    }
  }
  @-o-keyframes first_half_11 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-140.4deg);
    }
  }
  @keyframes first_half_11 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-140.4deg);
    }
  }
  [data-p="12"] .second_half {
    opacity: 0;
  }
  [data-p="12"] .first_half {
    animation: 1s ease-in-out forwards first_half_12;
  }
  @-moz-keyframes first_half_12 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-136.8deg);
    }
  }
  @-webkit-keyframes first_half_12 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-136.8deg);
    }
  }
  @-o-keyframes first_half_12 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-136.8deg);
    }
  }
  @keyframes first_half_12 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-136.8deg);
    }
  }
  [data-p="13"] .second_half {
    opacity: 0;
  }
  [data-p="13"] .first_half {
    animation: 1s ease-in-out forwards first_half_13;
  }
  @-moz-keyframes first_half_13 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-133.2deg);
    }
  }
  @-webkit-keyframes first_half_13 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-133.2deg);
    }
  }
  @-o-keyframes first_half_13 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-133.2deg);
    }
  }
  @keyframes first_half_13 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-133.2deg);
    }
  }
  [data-p="14"] .second_half {
    opacity: 0;
  }
  [data-p="14"] .first_half {
    animation: 1s ease-in-out forwards first_half_14;
  }
  @-moz-keyframes first_half_14 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-129.6deg);
    }
  }
  @-webkit-keyframes first_half_14 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-129.6deg);
    }
  }
  @-o-keyframes first_half_14 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-129.6deg);
    }
  }
  @keyframes first_half_14 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-129.6deg);
    }
  }
  [data-p="15"] .second_half {
    opacity: 0;
  }
  [data-p="15"] .first_half {
    animation: 1s ease-in-out forwards first_half_15;
  }
  @-moz-keyframes first_half_15 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-126deg);
    }
  }
  @-webkit-keyframes first_half_15 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-126deg);
    }
  }
  @-o-keyframes first_half_15 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-126deg);
    }
  }
  @keyframes first_half_15 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-126deg);
    }
  }
  [data-p="16"] .second_half {
    opacity: 0;
  }
  [data-p="16"] .first_half {
    animation: 1s ease-in-out forwards first_half_16;
  }
  @-moz-keyframes first_half_16 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-122.4deg);
    }
  }
  @-webkit-keyframes first_half_16 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-122.4deg);
    }
  }
  @-o-keyframes first_half_16 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-122.4deg);
    }
  }
  @keyframes first_half_16 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-122.4deg);
    }
  }
  [data-p="17"] .second_half {
    opacity: 0;
  }
  [data-p="17"] .first_half {
    animation: 1s ease-in-out forwards first_half_17;
  }
  @-moz-keyframes first_half_17 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-118.8deg);
    }
  }
  @-webkit-keyframes first_half_17 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-118.8deg);
    }
  }
  @-o-keyframes first_half_17 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-118.8deg);
    }
  }
  @keyframes first_half_17 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-118.8deg);
    }
  }
  [data-p="18"] .second_half {
    opacity: 0;
  }
  [data-p="18"] .first_half {
    animation: 1s ease-in-out forwards first_half_18;
  }
  @-moz-keyframes first_half_18 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-115.2deg);
    }
  }
  @-webkit-keyframes first_half_18 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-115.2deg);
    }
  }
  @-o-keyframes first_half_18 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-115.2deg);
    }
  }
  @keyframes first_half_18 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-115.2deg);
    }
  }
  [data-p="19"] .second_half {
    opacity: 0;
  }
  [data-p="19"] .first_half {
    animation: 1s ease-in-out forwards first_half_19;
  }
  @-moz-keyframes first_half_19 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-111.6deg);
    }
  }
  @-webkit-keyframes first_half_19 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-111.6deg);
    }
  }
  @-o-keyframes first_half_19 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-111.6deg);
    }
  }
  @keyframes first_half_19 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-111.6deg);
    }
  }
  [data-p="20"] .second_half {
    opacity: 0;
  }
  [data-p="20"] .first_half {
    animation: 1s ease-in-out forwards first_half_20;
  }
  @-moz-keyframes first_half_20 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-108deg);
    }
  }
  @-webkit-keyframes first_half_20 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-108deg);
    }
  }
  @-o-keyframes first_half_20 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-108deg);
    }
  }
  @keyframes first_half_20 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-108deg);
    }
  }
  [data-p="21"] .second_half {
    opacity: 0;
  }
  [data-p="21"] .first_half {
    animation: 1s ease-in-out forwards first_half_21;
  }
  @-moz-keyframes first_half_21 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-104.39999999999999deg);
    }
  }
  @-webkit-keyframes first_half_21 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-104.39999999999999deg);
    }
  }
  @-o-keyframes first_half_21 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-104.39999999999999deg);
    }
  }
  @keyframes first_half_21 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-104.39999999999999deg);
    }
  }
  [data-p="22"] .second_half {
    opacity: 0;
  }
  [data-p="22"] .first_half {
    animation: 1s ease-in-out forwards first_half_22;
  }
  @-moz-keyframes first_half_22 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-100.8deg);
    }
  }
  @-webkit-keyframes first_half_22 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-100.8deg);
    }
  }
  @-o-keyframes first_half_22 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-100.8deg);
    }
  }
  @keyframes first_half_22 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-100.8deg);
    }
  }
  [data-p="23"] .second_half {
    opacity: 0;
  }
  [data-p="23"] .first_half {
    animation: 1s ease-in-out forwards first_half_23;
  }
  @-moz-keyframes first_half_23 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-97.2deg);
    }
  }
  @-webkit-keyframes first_half_23 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-97.2deg);
    }
  }
  @-o-keyframes first_half_23 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-97.2deg);
    }
  }
  @keyframes first_half_23 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-97.2deg);
    }
  }
  [data-p="24"] .second_half {
    opacity: 0;
  }
  [data-p="24"] .first_half {
    animation: 1s ease-in-out forwards first_half_24;
  }
  @-moz-keyframes first_half_24 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-93.6deg);
    }
  }
  @-webkit-keyframes first_half_24 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-93.6deg);
    }
  }
  @-o-keyframes first_half_24 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-93.6deg);
    }
  }
  @keyframes first_half_24 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-93.6deg);
    }
  }
  [data-p="25"] .second_half {
    opacity: 0;
  }
  [data-p="25"] .first_half {
    animation: 1s ease-in-out forwards first_half_25;
  }
  @-moz-keyframes first_half_25 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-90deg);
    }
  }
  @-webkit-keyframes first_half_25 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-90deg);
    }
  }
  @-o-keyframes first_half_25 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-90deg);
    }
  }
  @keyframes first_half_25 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-90deg);
    }
  }
  [data-p="26"] .second_half {
    opacity: 0;
  }
  [data-p="26"] .first_half {
    animation: 1s ease-in-out forwards first_half_26;
  }
  @-moz-keyframes first_half_26 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-86.39999999999999deg);
    }
  }
  @-webkit-keyframes first_half_26 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-86.39999999999999deg);
    }
  }
  @-o-keyframes first_half_26 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-86.39999999999999deg);
    }
  }
  @keyframes first_half_26 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-86.39999999999999deg);
    }
  }
  [data-p="27"] .second_half {
    opacity: 0;
  }
  [data-p="27"] .first_half {
    animation: 1s ease-in-out forwards first_half_27;
  }
  @-moz-keyframes first_half_27 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-82.8deg);
    }
  }
  @-webkit-keyframes first_half_27 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-82.8deg);
    }
  }
  @-o-keyframes first_half_27 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-82.8deg);
    }
  }
  @keyframes first_half_27 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-82.8deg);
    }
  }
  [data-p="28"] .second_half {
    opacity: 0;
  }
  [data-p="28"] .first_half {
    animation: 1s ease-in-out forwards first_half_28;
  }
  @-moz-keyframes first_half_28 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-79.2deg);
    }
  }
  @-webkit-keyframes first_half_28 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-79.2deg);
    }
  }
  @-o-keyframes first_half_28 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-79.2deg);
    }
  }
  @keyframes first_half_28 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-79.2deg);
    }
  }
  [data-p="29"] .second_half {
    opacity: 0;
  }
  [data-p="29"] .first_half {
    animation: 1s ease-in-out forwards first_half_29;
  }
  @-moz-keyframes first_half_29 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-75.6deg);
    }
  }
  @-webkit-keyframes first_half_29 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-75.6deg);
    }
  }
  @-o-keyframes first_half_29 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-75.6deg);
    }
  }
  @keyframes first_half_29 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-75.6deg);
    }
  }
  [data-p="30"] .second_half {
    opacity: 0;
  }
  [data-p="30"] .first_half {
    animation: 1s ease-in-out forwards first_half_30;
  }
  @-moz-keyframes first_half_30 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-72deg);
    }
  }
  @-webkit-keyframes first_half_30 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-72deg);
    }
  }
  @-o-keyframes first_half_30 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-72deg);
    }
  }
  @keyframes first_half_30 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-72deg);
    }
  }
  [data-p="31"] .second_half {
    opacity: 0;
  }
  [data-p="31"] .first_half {
    animation: 1s ease-in-out forwards first_half_31;
  }
  @-moz-keyframes first_half_31 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-68.39999999999999deg);
    }
  }
  @-webkit-keyframes first_half_31 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-68.39999999999999deg);
    }
  }
  @-o-keyframes first_half_31 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-68.39999999999999deg);
    }
  }
  @keyframes first_half_31 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-68.39999999999999deg);
    }
  }
  [data-p="32"] .second_half {
    opacity: 0;
  }
  [data-p="32"] .first_half {
    animation: 1s ease-in-out forwards first_half_32;
  }
  @-moz-keyframes first_half_32 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-64.8deg);
    }
  }
  @-webkit-keyframes first_half_32 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-64.8deg);
    }
  }
  @-o-keyframes first_half_32 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-64.8deg);
    }
  }
  @keyframes first_half_32 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-64.8deg);
    }
  }
  [data-p="33"] .second_half {
    opacity: 0;
  }
  [data-p="33"] .first_half {
    animation: 1s ease-in-out forwards first_half_33;
  }
  @-moz-keyframes first_half_33 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-61.2deg);
    }
  }
  @-webkit-keyframes first_half_33 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-61.2deg);
    }
  }
  @-o-keyframes first_half_33 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-61.2deg);
    }
  }
  @keyframes first_half_33 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-61.2deg);
    }
  }
  [data-p="34"] .second_half {
    opacity: 0;
  }
  [data-p="34"] .first_half {
    animation: 1s ease-in-out forwards first_half_34;
  }
  @-moz-keyframes first_half_34 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-57.599999999999994deg);
    }
  }
  @-webkit-keyframes first_half_34 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-57.599999999999994deg);
    }
  }
  @-o-keyframes first_half_34 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-57.599999999999994deg);
    }
  }
  @keyframes first_half_34 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-57.599999999999994deg);
    }
  }
  [data-p="35"] .second_half {
    opacity: 0;
  }
  [data-p="35"] .first_half {
    animation: 1s ease-in-out forwards first_half_35;
  }
  @-moz-keyframes first_half_35 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-54deg);
    }
  }
  @-webkit-keyframes first_half_35 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-54deg);
    }
  }
  @-o-keyframes first_half_35 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-54deg);
    }
  }
  @keyframes first_half_35 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-54deg);
    }
  }
  [data-p="36"] .second_half {
    opacity: 0;
  }
  [data-p="36"] .first_half {
    animation: 1s ease-in-out forwards first_half_36;
  }
  @-moz-keyframes first_half_36 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-50.400000000000006deg);
    }
  }
  @-webkit-keyframes first_half_36 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-50.400000000000006deg);
    }
  }
  @-o-keyframes first_half_36 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-50.400000000000006deg);
    }
  }
  @keyframes first_half_36 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-50.400000000000006deg);
    }
  }
  [data-p="37"] .second_half {
    opacity: 0;
  }
  [data-p="37"] .first_half {
    animation: 1s ease-in-out forwards first_half_37;
  }
  @-moz-keyframes first_half_37 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-46.79999999999998deg);
    }
  }
  @-webkit-keyframes first_half_37 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-46.79999999999998deg);
    }
  }
  @-o-keyframes first_half_37 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-46.79999999999998deg);
    }
  }
  @keyframes first_half_37 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-46.79999999999998deg);
    }
  }
  [data-p="38"] .second_half {
    opacity: 0;
  }
  [data-p="38"] .first_half {
    animation: 1s ease-in-out forwards first_half_38;
  }
  @-moz-keyframes first_half_38 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-43.19999999999999deg);
    }
  }
  @-webkit-keyframes first_half_38 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-43.19999999999999deg);
    }
  }
  @-o-keyframes first_half_38 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-43.19999999999999deg);
    }
  }
  @keyframes first_half_38 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-43.19999999999999deg);
    }
  }
  [data-p="39"] .second_half {
    opacity: 0;
  }
  [data-p="39"] .first_half {
    animation: 1s ease-in-out forwards first_half_39;
  }
  @-moz-keyframes first_half_39 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-39.599999999999994deg);
    }
  }
  @-webkit-keyframes first_half_39 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-39.599999999999994deg);
    }
  }
  @-o-keyframes first_half_39 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-39.599999999999994deg);
    }
  }
  @keyframes first_half_39 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-39.599999999999994deg);
    }
  }
  [data-p="40"] .second_half {
    opacity: 0;
  }
  [data-p="40"] .first_half {
    animation: 1s ease-in-out forwards first_half_40;
  }
  @-moz-keyframes first_half_40 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-36deg);
    }
  }
  @-webkit-keyframes first_half_40 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-36deg);
    }
  }
  @-o-keyframes first_half_40 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-36deg);
    }
  }
  @keyframes first_half_40 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-36deg);
    }
  }
  [data-p="41"] .second_half {
    opacity: 0;
  }
  [data-p="41"] .first_half {
    animation: 1s ease-in-out forwards first_half_41;
  }
  @-moz-keyframes first_half_41 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-32.400000000000006deg);
    }
  }
  @-webkit-keyframes first_half_41 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-32.400000000000006deg);
    }
  }
  @-o-keyframes first_half_41 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-32.400000000000006deg);
    }
  }
  @keyframes first_half_41 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-32.400000000000006deg);
    }
  }
  [data-p="42"] .second_half {
    opacity: 0;
  }
  [data-p="42"] .first_half {
    animation: 1s ease-in-out forwards first_half_42;
  }
  @-moz-keyframes first_half_42 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-28.799999999999983deg);
    }
  }
  @-webkit-keyframes first_half_42 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-28.799999999999983deg);
    }
  }
  @-o-keyframes first_half_42 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-28.799999999999983deg);
    }
  }
  @keyframes first_half_42 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-28.799999999999983deg);
    }
  }
  [data-p="43"] .second_half {
    opacity: 0;
  }
  [data-p="43"] .first_half {
    animation: 1s ease-in-out forwards first_half_43;
  }
  @-moz-keyframes first_half_43 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-25.19999999999999deg);
    }
  }
  @-webkit-keyframes first_half_43 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-25.19999999999999deg);
    }
  }
  @-o-keyframes first_half_43 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-25.19999999999999deg);
    }
  }
  @keyframes first_half_43 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-25.19999999999999deg);
    }
  }
  [data-p="44"] .second_half {
    opacity: 0;
  }
  [data-p="44"] .first_half {
    animation: 1s ease-in-out forwards first_half_44;
  }
  @-moz-keyframes first_half_44 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-21.599999999999994deg);
    }
  }
  @-webkit-keyframes first_half_44 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-21.599999999999994deg);
    }
  }
  @-o-keyframes first_half_44 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-21.599999999999994deg);
    }
  }
  @keyframes first_half_44 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-21.599999999999994deg);
    }
  }
  [data-p="45"] .second_half {
    opacity: 0;
  }
  [data-p="45"] .first_half {
    animation: 1s ease-in-out forwards first_half_45;
  }
  @-moz-keyframes first_half_45 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-18deg);
    }
  }
  @-webkit-keyframes first_half_45 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-18deg);
    }
  }
  @-o-keyframes first_half_45 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-18deg);
    }
  }
  @keyframes first_half_45 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-18deg);
    }
  }
  [data-p="46"] .second_half {
    opacity: 0;
  }
  [data-p="46"] .first_half {
    animation: 1s ease-in-out forwards first_half_46;
  }
  @-moz-keyframes first_half_46 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-14.400000000000006deg);
    }
  }
  @-webkit-keyframes first_half_46 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-14.400000000000006deg);
    }
  }
  @-o-keyframes first_half_46 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-14.400000000000006deg);
    }
  }
  @keyframes first_half_46 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-14.400000000000006deg);
    }
  }
  [data-p="47"] .second_half {
    opacity: 0;
  }
  [data-p="47"] .first_half {
    animation: 1s ease-in-out forwards first_half_47;
  }
  @-moz-keyframes first_half_47 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-10.799999999999983deg);
    }
  }
  @-webkit-keyframes first_half_47 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-10.799999999999983deg);
    }
  }
  @-o-keyframes first_half_47 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-10.799999999999983deg);
    }
  }
  @keyframes first_half_47 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-10.799999999999983deg);
    }
  }
  [data-p="48"] .second_half {
    opacity: 0;
  }
  [data-p="48"] .first_half {
    animation: 1s ease-in-out forwards first_half_48;
  }
  @-moz-keyframes first_half_48 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-7.199999999999989deg);
    }
  }
  @-webkit-keyframes first_half_48 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-7.199999999999989deg);
    }
  }
  @-o-keyframes first_half_48 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-7.199999999999989deg);
    }
  }
  @keyframes first_half_48 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-7.199999999999989deg);
    }
  }
  [data-p="49"] .second_half {
    opacity: 0;
  }
  [data-p="49"] .first_half {
    animation: 1s ease-in-out forwards first_half_49;
  }
  @-moz-keyframes first_half_49 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-3.599999999999994deg);
    }
  }
  @-webkit-keyframes first_half_49 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-3.599999999999994deg);
    }
  }
  @-o-keyframes first_half_49 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-3.599999999999994deg);
    }
  }
  @keyframes first_half_49 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(-3.599999999999994deg);
    }
  }
  [data-p="50"] .second_half {
    opacity: 0;
  }
  [data-p="50"] .first_half {
    animation: 1s ease-in-out forwards first_half_50;
  }
  @-moz-keyframes first_half_50 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @-webkit-keyframes first_half_50 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @-o-keyframes first_half_50 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  @keyframes first_half_50 {
    from {
      transform: rotate(-180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  