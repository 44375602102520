/***************
*****GENERAL****
***************/

.cursorPointer {
  cursor: pointer;
}

.noPaddingRight {
  padding-right: 0 !important;
}

.noOverflow,
.noOverflow > *,
.noOverflow > * > * {
  overflow: visible !important;
}

.zIndex1 {
  z-index: 1 !important;
}

.noMargin {
  margin: 0 !important;
}

.noPadding {
  padding: 0 !important;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}
.noPaddingTop {
  padding-top: 0 !important;
}

.marginAuto {
  margin: auto;
}

.margin-bottom {
  margin-bottom: 1rem !important;
}

.margin-bottom2rem {
  margin-bottom: 2rem !important;
}

.marginTop1rem {
  margin-top: 1rem !important;
}

.marginTop2rem {
  margin-top: 2rem !important;
}

.marginTop3rem {
  margin-top: 3rem !important;
}

.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.textAlignEnd {
  text-align: end;
}

.minHeight400 {
  min-height: 400px;
}

.height100 {
  height: 100% !important;
}

.uppercase {
  text-transform: uppercase;
}

input:disabled {
  background-color: rgb(239, 239, 239) !important;
}

@media screen and (max-width: 1200px) {
  :root {
    font-size: 13.5px !important;
  }
}

@media screen and (max-width: 768px) {
  :root {
    font-size: 11px !important;
  }
}

/***************
***Video-React**
***************/

.video-react .video-react-big-play-button:before {
  background: url('../images/btn_play.svg') no-repeat !important;
  background-size: 30% !important;
  background-position: center !important;
  left: 2px !important;
  content: '' !important;
}

.video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button:focus {
  transition: all 0.2s ease-in-out !important;
}

.video-react .video-react-poster {
  background-size: cover !important;
}

.MuiCircularProgress-circle {
  stroke: unset !important;
}

/****************
****ANIMATIONS***
*****************/

.animateBackgroundGreen {
  background: linear-gradient(
    98deg,
    #adffa7 2%,
    #5dcc3a 25%,
    #149703 50%,
    #47b825 75%,
    #74e96b 90%
  );
  background-size: 400% 400% !important;
  animation: 2.5s ease-in-out 0s infinite alternate animateBackground;
}

.animateBackgroundOrange {
  background: linear-gradient(
    98deg,
    #ffc977 2%,
    #cc8b3a 25%,
    #ce7c00 50%,
    #b88b25 75%,
    #e9b76b 90%
  );
  background-size: 400% 400% !important;
  animation: 2.5s ease-in-out 0s infinite alternate animateBackground;
}

.animateBackgroundBlue {
  background: linear-gradient(
    98deg,
    rgb(188, 254, 253) 2%,
    rgb(105, 218, 217) 25%,
    rgb(53, 187, 184) 50%,
    rgb(105, 218, 217) 75%,
    rgb(188, 254, 253) 90%
  );
  background-size: 400% 400% !important;
  animation: 2.5s ease-in-out 0s infinite alternate animateBackground;
}

.animateBoxShadowGreen {
  box-shadow: rgb(126 207 64 / 20%) 0px 0px 18px 7px,
    rgb(126 207 64 / 15%) 0px 0px 18px 7px inset;
  animation: 2.5s ease-in-out 0s infinite alternate animateBoxShadowGreen;
}
.animateBoxShadowOrange {
  box-shadow: rgb(207 183 64 / 20%) 0px 0px 18px 7px,
    rgb(207 189 64 / 15%) 0px 0px 18px 7px inset;
  animation: 2.5s ease-in-out 0s infinite alternate animateBoxShadowOrange;
}
.animateBoxShadowBlue {
  box-shadow: rgba(19, 105, 103, 20%) 0px 0px 18px 7px,
    rgba(19, 105, 103, 15%) 0px 0px 18px 7px inset;
  animation: 2.5s ease-in-out 0s infinite alternate animateBoxShadowBlue;
}

/* .animateGreenProgress div {
  background-image: linear-gradient(
    90deg,
    #adffa7 2%,
    #75ee50 15%,
    #92e978 25%,
    #1bce04 50%,
    #50e224 75%,
    #adffa7 90%
  ) !important;
  background-size: 400% 100% !important;
  animation: 2s linear 0s infinite alternate-reverse animateBackground;
} */

@keyframes animateBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes animateBoxShadowGreen {
  0% {
    box-shadow: rgb(126 207 64 / 20%) 0px 0px 18px 7px,
      rgb(126 207 64 / 15%) 0px 0px 18px 7px inset;
  }
  50% {
    box-shadow: rgb(126 207 64 / 10%) 0px 0px 18px 7px,
      rgb(126 207 64 / 5%) 0px 0px 18px 7px inset;
  }
  100% {
    box-shadow: rgb(126 207 64 / 35%) 0px 0px 18px 7px,
      rgb(126 207 64 / 30%) 0px 0px 18px 7px inset;
  }
}
@keyframes animateBoxShadowOrange {
  0% {
    box-shadow: rgb(207 183 64 / 20%) 0px 0px 18px 7px,
      rgb(207 189 64 / 15%) 0px 0px 18px 7px inset;
  }
  50% {
    box-shadow: rgb(207 183 64 / 10%) 0px 0px 18px 7px,
      rgb(207 189 64 / 15%) 0px 0px 18px 7px inset;
  }
  100% {
    box-shadow: rgb(207 183 64 / 35%) 0px 0px 18px 7px,
      rgb(207 189 64 / 15%) 0px 0px 18px 7px inset;
  }
}
@keyframes animateBoxShadowOrange {
  0% {
    box-shadow: rgba(19, 105, 103, 20%) 0px 0px 18px 7px,
      rgba(19, 105, 103, 15%) 0px 0px 18px 7px inset;
  }
  50% {
    box-shadow: rgba(19, 105, 103, 10%) 0px 0px 18px 7px,
      rgba(19, 105, 103, 15%) 0px 0px 18px 7px inset;
  }
  100% {
    box-shadow: rgba(19, 105, 103, 35%) 0px 0px 18px 7px,
      rgba(19, 105, 103, 15%) 0px 0px 18px 7px inset;
  }
}

/************
**HIGHCHART**
*************/

.highcharts-legend-item {
  text-transform: capitalize !important;
}
