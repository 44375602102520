@import "/../../theme/colors";

.bonus-screen {

  .bonus-title {
    padding: 60px 0px;

    @media screen and (max-width: 530px) {
      padding: 80px 0px;
    font-size: 16px;
  }

    text-align: center;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    background: $blueGradientTransparent;
    // padding: 128px 0px 40px;
  }

  .options {

    margin: 3vw 3vw;
    h2{
      margin: 20px auto;
    }
  }
  .footer-bg{
    background: $blueGradientTransparent;
  }
}