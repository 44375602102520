.logged-input-wrapper {
  margin-left: 10px;
  margin-right: 10px;

  .input-title {
    color: #fff;
    margin-bottom: 13px;
  }

  .input {
    border: 1px solid #fff;
    border-radius: 6px;

    padding-left: 12px;
    padding-right: 12px;
    width: 240px;
    height: 40px;
    background: transparent;
    margin-bottom: 20px;
    color: #fff;

    &.small {
      width: 100px;
    }

    &.disabled {
      opacity: 0.4;
    }
  }

  @media screen and (max-width: 750px) {
    .input-title {
      font-size: 11px;
    }
    .input {
      width: 150px;
      height: 30px;
      margin-bottom: 10px;
      font-size: 9px;
    }
  }
}
