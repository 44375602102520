@import "../../../theme/colors";

.footer-wrapper {
  position      : relative;
  padding       : 100px 30px 100px 30px;
  flex-direction: column;
  align-items   : center;
  justify-items : center;
  align-content : center;
  height        : 500px;
  color         : #fff;

  background   : $blueGradient;
  // background: linear-gradient(345.22deg, #623C99 25.38%, #331C60 101.77%);

  opacity        : 1;
  text-align     : center;
  display        : flex;
  justify-content: space-between;

  .footer-links {
    width         : 100%;
    z-index       : 2;
    font-family   : "Montserrat", 'Helvetica Neue', sans-serif;
    font-size     : 15px;
    text-transform: uppercase;
    margin-top: 15px;
    
    .link {
      cursor: pointer;
    }
  }

  .footer_risques{
    font-size: .9rem;
    color: rgba(255, 255, 255, 0.404);
    line-height: 15px;
    text-decoration: none;
    margin: 30px 0;
  }
  

  page-title {
    text-transform: none !important;
  }

  .background {
    z-index : 1;
    position: absolute;

    top                : 0;
    left               : 0;
    right              : 0;
    bottom             : 0;
    background         : url("../../../images/footer-bg.png");
    background-size    : cover;
    background-position: center;
    opacity            : 0.16;
  }

  .link {
    z-index        : 2;
    color          : #fff;
    margin-left    : 10px;
    margin-right   : 10px;
    text-decoration: none;
  }

  .link-sep {
    z-index: 2;
  }

  .social-link {
    z-index: 2;

    margin: 10px;
    width : 26px;
    height: 26px;
  }

  .social-link img {
    width     : 100%;
    height    : 100%;
    object-fit: contain;
  }

  .sep {
    z-index: 2;

    width        : 100px;
    height       : 1px;
    background   : #fff;
    border-radius: 2px;

    margin-top   : 30px;
    margin-bottom: 20px;
  }

  .logo {
    // width           : 120px;
    height          : 170px;
    margin          : auto;
    object-fit      : contain;
    // margin-bottom: 30px;
  }

  .copyright {
    z-index   : 2;
    margin-top: 40px;
    font-size : 15px;
  }

  .pay-secure-img {
    background         : url("../../../images/secure-pay.png") no-repeat;
    background-size    : cover;
    background-position: center;
    width              : 159px;
    height             : 26px;
  }

  .row-bottom {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : flex-end;
  }

  .pay-secure {
    padding-bottom: 5px;
    font-size     : 12px;
  }

  .border-pay {
    border           : 1px solid #ffffff91;
    // margin: 40px;
    padding          : 25px;
    // padding-bottom: 19px;
    padding-top      : 19px;
    width            : 221px;
    height           : 10%;
    display          : none;
  }
}

.border-pay-responsive {
  display: none;
}

.row-center-responsive {
  display: none;
}

@media screen and (max-width: 479px) {
  .footer-links {
    font-size: 14px;
  }

  .logo {
    margin-top   : 30px;
    margin-bottom: 0;
  }

  .copyright {
    font-size  : 11px;
    font-weight: 400;
    margin-top : 25px;
  }

  .sep {
    display: none;
  }

  .border-pay-responsive {
    display       : block;
    border        : 1px solid rgb(255, 255, 255);
    margin        : 40px;
    padding       : 25px;
    padding-bottom: 19px;
    padding-top   : 19px;
  }

  .row-center-responsive {
    display    : none;
    font-size  : 8px;
    font-weight: 600;
  }

  .col-center.hide {
    // display: none;
  }

  .row-bottom {
    display: none !important;
  }
}

@media (max-width: 900px) {
  .footer-wrapper {
    padding: 70px 30px 30px 30px;
    height : 250px;

    .logo {
      height: 58px;
    }

    .footer-links {
      font-size     : 7px;
      letter-spacing: 0.5px;
    }

    .link{
      // margin-left : unset;
      // margin-right: unset;
    }

    .copyright {
      font-size: 7px;
    }
  }

  .copyright {
    font-size  : 11px;
    font-weight: 400;
    margin-top : 25px;
  }

  .footer_risques{
    font-size: .7rem !important;
  }

  .sep {
    display: none;
  }

  .border-pay-responsive {
    display       : flex;
    border        : 1px solid #ffffff91;
    padding       : 19px;
    align-items   : center;
    flex-direction: column;
    width         : 221px;
  }

  .row-center-responsive {
    display    : flex;
    font-size  : 8px;
    font-weight: 600;
  }

  .col-center.hide {
    // display: none;
  }

  .row-bottom {
    display: none !important;
  }
}