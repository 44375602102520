@import '../../../theme/colors';

.global-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 354px;
  min-height: 77px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 5px;
  box-shadow: 0px 3px 10px -5px gray;
  color: #333;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  z-index: 3;
  transition: background-color 0.1s ease-in-out;
  transition: 0.3s;
  font-family: 'AvenirNext-DemiBold', 'Montserrat', 'Helvetica Neue', sans-serif;

  &.round-btn {
    border-radius: 25px;
    width: 236px;
    height: 51px;
    text-align: center;
    font-size: 12px;
  }

  &.teal-btn {
    background: $teal;
    color: #000;
  }

  &.linear-btn {
    background: linear-gradient(
      298.91deg,
      $gradientStart 17.79%,
      $gradientEnd 170.12%
    );
    color: #000;
  }

  &.clear-btn {
    background: #fff;
    color: $purple;
  }

  &.purple-btn {
    background: $purple;
    color: #fff;
  }

  &.purple-linear-btn {
    background: linear-gradient(214.28deg, #26ab81 -36.35%, #3d175f 90.73%);
    color: #fff;
  }

  &.big-btn {
    height: 46px;
    padding-left: 30px;
    padding-right: 30px;
    width: 305px;
  }

  &.centered {
    margin: auto;
  }

  &:hover {
    // opacity: 0.8;
    cursor: pointer;
    scale: 1.05;
  }

  &.disabled {
    opacity: 0.8;
  }

  &.small-font {
    font-size: 14px;
  }
}

.arrow_btn {
  width: 32px;
}

.arrow_btn_small {
  width: 19px;
  min-width: 19px;
  max-width: 19px;
}

.small {
  width: 148px !important;
  height: 39px !important;
  max-height: 39px;
  min-height: 39px !important;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 22px;
}

.bonus {
  width: 245px !important;
  max-height: 61px !important;
  height: 61px !important;
  font-size: 20px;
}

.data {
  padding-left: 10px;
  padding-right: 10px;
  width: 80% !important;
  height: 58px !important;
  max-height: unset;
  font-size: 1.1em;
  justify-content: center;
  font-weight: 500;
}

.button-title {
  margin: auto;
}

.sub {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 750px) {
  .global-btn {
    width: 200px;
    height: 46px;
    min-height: 29px;
    font-size: 12px;
    // display: none;
  }

  .small {
    width: 148px !important;
    height: 39px !important;
    max-height: 39px;
    min-height: 39px !important;
  }
}
