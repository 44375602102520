.add-card-screen {
  .credit_card-box {
    background: #f2f2f222;
    border: solid 2px #ccc;
    display: flex;
    flex-direction: column;
    justify-items: center;
    max-width: 400px;
    margin: 20px auto;
    .credit_card {
      background: #f2f2f2;
      border: solid 2px #ccc;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 355px;
      margin: 40px auto 0px auto;
      padding: 6px 23px 23px 23px;

      .cc_container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      .badge_stripe {
        max-height: 30px;
        max-width: 30%;
        margin: 0 0 5px auto;
      }
    }

    .badges {
      max-height: 50px;
      max-width: 60%;
      margin: 10px auto;
    }

    /* 
    The StripeElement class is applied to the Element container by default.
    More info: https://stripe.com/docs/stripe-js/reference#element-options
    */

    .StripeElement,
    .StripeElementIdeal {
      display: block;
      margin: 0px 0 5px 0;
      max-width: 500px;
      padding: 10px 14px;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border-radius: 4px;
      background: white;
    }

    .StripeElement--focus,
    .StripeElementIdeal--focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }

    .StripeElement.loading {
      height: 41.6px;
      opacity: 0.6;
    }

    .StripeElementIdeal {
      padding: 0;
    }

    .payment-screen {
      .content-box {
        height: 100%;
      }

      .payment-title {
        text-align: center;
        font-family: 'AvenirNext-HeavyItalic', 'Helvetica Neue', sans-serif;

        font-size: 27px;
        margin-bottom: 40px;
      }

      .payment-intro {
        font-family: 'AvenirNext-Italic', 'Helvetica Neue', sans-serif;
        margin-bottom: 40px;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
      }

      .no-engage {
        // margin-top: 59%;
      }

      .confirm-box {
        border: 10px solid #979797;

        background: rgba(255, 255, 255, 0.28);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 40px;
        height: 407px;
      }

      .box-content {
        font-family: 'AvenirNext-HeavyItalic', 'Helvetica Neue', sans-serif;
        width: 502px;
        height: 121px;
        font-size: 37.53px;
        font-weight: 400;
        line-height: 50px;
        text-align: center;
      }

      .btns {
        font-family: 'AvenirNext-DemiBold', 'Helvetica Neue', sans-serif;
        font-size: 23px;
        display: flex;
        flex-direction: row;
        align-items: center;

        div {
          margin: 20px;
        }
      }
    }

    .mentions_under-btn {
      color: #9a9a9a !important;
      font-size: 0.7rem;
      margin-top: 13px;
      transition: color 0.2s ease-in-out;
      line-height: 20px;

      & a {
        color: #9a9a9a !important;
        text-transform: none;
        text-decoration: none;

        &:hover {
          color: #757575 !important;
        }
      }
    }

    @media screen and (max-width: 750px) {
      .payment-screen .no-engage {
        margin-top: 20% !important;
      }

      .payment-title {
        font-size: 16px !important;
      }

      .payment-intro {
        margin-bottom: 0px !important;

        .content-box {
          margin: 0 !important;
          padding-top: 120px !important;
        }

        .confirm-box {
          height: 300px !important;
        }

        .box-content {
          font-family: 'AvenirNext-HeavyItalic', 'Helvetica Neue', sans-serif;
          width: unset !important;
          height: 121px;
          font-size: 20px !important;
          font-weight: 400;
          line-height: 50px;
          text-align: center;
        }

        .btns {
          font-size: 18px !important;
        }
      }
    }
  }
}
