.response-box {

  margin-bottom: 6px;
  border: 2px solid #B77BFF;
  border-radius: 6px;

  padding: 20px;
  cursor: pointer;

  &.selected {
    background: linear-gradient(126.12deg, #21F3DC -21.41%, #B77BFF 100%);
    border: 2px solid transparent;
  }

  &.big {
    padding-top: 45px;
    padding-bottom: 45px;
  }

}