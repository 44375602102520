@import "../../../theme/colors";

.bg-color {
  background: rgba(0, 0, 0, 0)
    linear-gradient(270deg, rgb(89, 76, 101) -100%, rgb(50, 47, 53) 100%) repeat
    scroll 0% 0%;
  width: 67%;
  margin: 16px;
  border-radius: 5px;
}

.recent-prono {
  // height   : 228px !important;
  // width : 50%;
}

.prono-wrapper {
  &.home {
    border-radius: 4px;
    min-height: unset !important;
    margin: auto;
    background: $purpleBlue;
    max-width: unset;
  }

  align-items: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
  border: solid 1 #979797;
  // padding-bottom: 1.6em;

  .header-prono {
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 20px 60px;
    justify-content: space-between;
    width: 100%;

    .mask {
      width: 51px;
      height: 51px;
      border-radius: 3px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

      .day {
        height: 26px;
        color: #0c1e4d;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.4px;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .month {
        height: 25px;
        background: #201d1e;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 7px;
        font-weight: 700;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.2px;
      }
    }
  }

  .prediction-frame {
    // opacity: 0.1;
    width: 95%;

    // height: 147px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 2px 4px #e0e1e40f;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 36px 12px 36px;
    margin: 0 20px 18px 20px;
    justify-content: space-between;

    .prediction {
      width: 51%;
      height: 58px;
      border-radius: 5px;
      background: linear-gradient(214deg, #000000, #322f35);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      z-index: 3;
      padding: 0px 48px 0px 48px;
      display: flex;
      justify-content: space-between;
      color: white;
      align-items: center;
      font-size: 17px;

      .predicted-team {
        width: 22%;
        text-align: center;
        font-family: AvenirNext-DemiBold;
        font-weight: bolder;
        font-size: 17px;
      }
    }

    .cote {
      color: white;
      text-align: center;
      font-size: 11px;
      letter-spacing: 0.4px;

      .val {
        font-size: 29px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.52;
        margin-left: 15px;
      }
    }
  }

  @media screen and (max-width: 67%) {
    margin: 20px 20px;
    width: 90%;
    height: 192px;

    .team-logo {
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
    }

    .team-logo {
      img {
        width: 45px !important;
      }
    }

    .team-logo.resize {
      width: 45px;
      height: 80px;
      justify-content: center;
    }
  }

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
  }

  &.active,
  &:hover {
    .bg-img {
      opacity: 1;
    }
  }

  .veil {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));

    z-index: 2;
  }

  .prono-header {
    padding: 20px 20px 0px 20px;
  }

  .teams {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
    padding: 0px 4vw;
    margin-top: 10px;

    .team-logo {
      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;

      display: flex;
      flex: 1;

      img {
        width: 100%;
        height: 90%;
        object-fit: contain;
      }
    }

    .team-1 {
      // margin-left: 149px;
      margin-right: 5px;
    }

    .team-2 {
      // margin-right: 149px;
      margin-left: 5px;
    }

    .team-name {
      text-align: center;
      font-family: "AvenirNext-HeavyItalic", "Helvetica Neue", sans-serif;
      display: flex;
      font-size: 1.3vw;
      font-weight: 400;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;
      height: 32px;
    }
  }

  .tournament {
    font-family: "Avenir-Heavy", "Helvetica Neue", sans-serif;
    font-size: 21.168px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .tournament-logo {
    // position: absolute;
    // right: 60px;
    // bottom: 20px;

    img {
      max-width: 107px;
      height: 64px;
    }
  }

  .check {
    width: 51px;
  }
}

@media screen and (max-width: 800px) {
  .bg-color {
    background: rgba(0, 0, 0, 0)
      linear-gradient(270deg, #594c65 -100%, #322f35 100%) repeat scroll 0% 0%;
    width: 95%;
    margin: 10px;
    border-radius: 5px;
    max-height: 150px;
  }

  .recent-prono {
    height: unset !important;
    width: 95%;
  }

  .prono-wrapper {
    &.home {
      max-width: 100%;
      min-height: unset !important;
      min-height: unset !important;

      .recent-prono {
        height: 135px !important;
        width: 95%;
      }
    }

    padding-bottom: unset;

    .header-prono {
      padding: 12px;

      .tournament-logo {
        img {
          width: 32px;
        }
      }
    }

    // padding-bottom: 0.6em;

    .prediction-frame {
      width: 95%;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 2px 4px #e0e1e40f;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px;
      justify-content: space-between;
      margin: 0 12px 12px 12px;

      .prediction {
        width: 45%;
        height: 28px;
        border-radius: 5px;
        background: linear-gradient(214deg, #000000, #322f35);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        z-index: 3;
        padding: 0px 27px 0px 15px;
        display: flex;
        justify-content: space-between;
        color: white;
        align-items: center;
        font-size: 7px;

        .predicted-team {
          font-size: 15px;
        }
      }

      .cote {
        color: white;
        text-align: center;
        font-size: 8px;
        letter-spacing: 0.4px;
        width: inherit !important;
        justify-content: end !important;

        .val {
          font-size: 15px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.52;
        }
      }

      .check {
        width: 34px;
      }
    }

    .header-prono {
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: row;
      // padding        : 3px 11px;
      justify-content: space-between;
      width: 100%;

      .mask {
        min-width: 32px;
        height: 37px;
        border-radius: 3px;
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        width: 32px;

        .month {
          height: 20px;
          background: #201d1e;
          color: #ffffff;
          font-family: Montserrat;
          font-size: 6px;
          font-weight: 700;
          line-height: 21px;
          text-align: center;
          letter-spacing: 0.2px;
        }

        .day {
          height: 18px;
          color: #0c1e4d;
          font-family: Montserrat;
          font-size: 8px;
          font-weight: 700;
          letter-spacing: 0.4px;
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }
    }

    .teams {
      flex: 1 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: unset;

      .team-name {
        height: unset;
        font-size: 10px !important;
      }

      /*! padding: 0px 5vw; */
      /*! margin-top: 10px; */
    }
  }

  .prono-header.row-spaced {
    justify-content: center;
  }

  .hour {
    font-family: "Avenir-Heavy", "Helvetica Neue", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
  }

  .date {
    font-family: "Avenir-Heavy", "Helvetica Neue", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    padding-right: 5px;
  }

  .team-name {
    font-family: "AvenirNext-HeavyItalic", "Helvetica Neue", sans-serif;
    font-size: 13.26px !important;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase;
  }

  .team-1 {
    margin-left: 10% !important;
    margin-right: 5px;
  }

  .team-2 {
    margin-right: 10% !important;
    margin-left: 5px;
  }

  .team-logo {
    height: 42px !important;
    min-width: 22px !important;
    min-height: 22px !important;
  }

  .tournament {
    font-family: "Avenir-Heavy", "Helvetica Neue", sans-serif;
    font-size: 8.17px !important;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: unset !important;
  }

  .tournament-logo {
    left: 83.5% !important;
    bottom: 15% !important;
    right: unset !important;
  }

  .tournament-logo img {
    width: 19px;
    height: 32px !important;
  }

  .prono-btn {
    font-size: 6.16896px;
    font-weight: 700;
    height: 26px;
  }
}

@media screen and (min-width: 750px) and (max-width: 1150px) {
  .prono-wrapper {
    .prono-content {
      .prediction-frame {
        height: 32% !important;
        padding: 20px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;

        .prediction {
          font-size: 15px;
          width: 90%;
          height: 38px;
          justify-content: space-around;

          .predicted-team {
            font-size: 16px;
            width: auto;
          }
        }

        .cote {
          width: 75%;
          font-size: 20px;
          justify-content: space-around !important;
        }
      }

      .title {
        .clubs {
          font-size: 21px;
        }
      }
    }
  }

  .data {
    height: 30px !important;
    max-width: 180px;
    font-size: 0.7em;
  }
}
