@import "./theme/colors";

/**
 * Reusable
 */


.page-title {
  font-weight   : bold;
  // color: $purple;
  font-size     : 40px;
  text-transform: uppercase;
  text-align    : center;
  padding-left  : 20px;
  padding-right : 20px;
}

.page-link {
  font-size      : 16px;
  cursor         : pointer;
  text-decoration: none;
}


@media screen and (max-width: 530px) {
  .page-title {
    font-size: 26px;
  }
}

.page-subtitle {
  font-size: 35px;
  
}

$spacing: 40px;

.red-box {
  border          : 1px solid #fff;
  border-radius   : 4px;
  // background   : rgba(255, 255, 255, 0.14);

  padding  : $spacing;
  max-width: 720px;

  margin-top   : 30px;
  margin-right : $spacing;
  margin-left  : $spacing;
  margin-bottom: 100px;

  color: #fff;
}

/**
 * Positioning
 */

.row {
  display       : flex;
  flex-direction: row;
  align-items   : center;
}

.row-center {
  display        : flex;
  flex-direction : row;
  justify-content: center;
  flex-wrap      : wrap;
  align-items    : center;
}

.row-end {
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : center;
}

.row-spaced {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
}

.col {
  display       : flex;
  flex-direction: column;
}

.col-center {
  display       : flex;
  flex-direction: column;
  align-items   : center;
}

.wrap {
  flex-wrap: wrap;
}

.bg-img {
  position  : absolute;
  bottom    : 0;
  left      : 0;
  right     : 0;
  top       : 0;
  width     : 100%;
  height    : 100%;
  z-index   : -1;
  object-fit: cover;
}

.dark-veil {
  background-color: rgba(0, 0, 0, 0.38);
}

.top-padding {
  padding-top   : 14vh;
  // padding-bottom: 50px;
}


/**
 * Texts
 */
.bold {
  font-weight: bold !important;
}

.green {
  color: $green !important;
}

.yellow {
  color: $yellow !important;
}

.white {
  color: #fff !important;
}

.black {
  color: #333 !important;
}

.error {
  color: $red !important;
}

@media screen and (max-width: 750px) {
  .page-title {
    font-size: 5vw;
  }

  .top-padding {
    padding-top: 0 !important;
  }

  .red-box {
    border       : none;
    border-radius: none;
    padding      : 0;
  }
}


.fade-in {
  animation        : fadeIn ease 4s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation   : fadeIn ease 1s;
  -o-animation     : fadeIn ease 1s;
  -ms-animation    : fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}