@import "../../../theme/variables";
@import "../../../theme/colors";

.main-side-bar-content {
  margin-left: $sideBarWidth;
  background: #fff;

  .top-logged-padding {
    // padding-top: 40px;
  }

  .page-logged-title {
    height: 120px;
    padding-top: 50px;
    text-transform: uppercase;
    margin-left: 50px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .user-link .fa {
      color: #80808050;
      font-size: 24px;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover,
      &-active {
        color: #173956 !important;
      }

    }
  }

  @media screen and (min-width: 750px) {
    .page-logged-title {
      padding-right: 10%;
    }
  }

  @media screen and (max-width: 750px) {
    .page-logged-title {
      height: 85px;
      padding-top: 35px;
      background: $purpleBlue;
      margin-bottom: 20px;
      margin-left: unset;
      position: fixed;
      width: 100%;
      z-index: 4;
      color: white;
      top: 0;
      display: block;

      .phone-hidden {
        display: none;
      }

      .title {
        text-align: center;
        font-size: 17px;
        color: white;
      }
    }

    .corner {
      background: $purpleBlueCorner;
    }

    .account {
      height: 100%;
      z-index: 1;
    }
  }

  .page-logged-subtitle {
    text-transform: uppercase;
    font-size: 16px;
    color: #fff;
  }
}

.content-box {
  background: linear-gradient(270.28deg, #09133c, #173956);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  margin: 0px 70px 40px 70px;
  padding: 100px 60px;
  height: 80vh;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .main-side-bar-content {
    margin-left: 0;
  }

  .content-box {
    background: linear-gradient(270.28deg, #09133c, #173956);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    margin: 0px 10px;
    padding: 20px;
  }

  .box-account {
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    margin: unset;
    padding: 100px 15px;
    height: 90%;
    position: fixed;
    z-index: 2;
  }
}