@import "../../../theme/colors";

.cards-list {
  display: flex;
  justify-content: center;
  // margin-top: 80px;
  // margin-bottom: 20px;

  .row {
    padding-top: 10px;
  }

  .card {
    margin-bottom: 12px;

    .card-content {
      border: 1px solid #fff;
      border-radius: 8px;

      display: flex;
      flex: 1;
      padding: 10px;

      .card-number {
      }
    }

    .card-remove {
      cursor: pointer;
      width: 40px;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-left: 22px;
    }

    .card-check {
      border: 1px solid #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $green;
      margin-left: 22px;
    }
  }

  .card-btn {
    font-family: "AvenirNext-DemiBold", "Helvetica Neue", sans-serif;
    height: 54px;
    border-radius: 8px;
    border: unset !important;
    // background: linear-gradient(298.91deg, #f0cf32 17.79%, #E73587 170.12%) !important;
    background: $teal;
    @media screen and (max-width: 750px) {
      font-size: 9px;
    }
  }
}
