.circle-data {
  
  width: 120px;
  height: 120px;
  border-radius: 50%;

  margin: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-weight: bold;
  color: #fff;

  border: 3px solid #fff;

  .circle-value {
    font-size: 22px;
  }

  .circle-title {
    margin-top: 8px;
    font-size: 13px;
    padding: 0px 10px;

    text-transform: uppercase;
  }

}