@import "../../../theme/colors";

$borderColor: #92A8EF;

.price-wrapper {
  background: transparent;
  border: 2px solid $borderColor;
  border-radius: 2px;
  height: 480px;
  // width: 280px;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  -webkit-box-shadow: -1px 0px 11px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: -1px 0px 11px 0px rgba(0,0,0,0.18);
  box-shadow: -1px 0px 11px 0px rgba(0,0,0,0.18);
  text-align: center;

  margin: 80px;


  .duration {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    font-family: "AvenirNext-DemiBold", 'Helvetica Neue', sans-serif;
    line-height: 19px;
  }

  .price {
    margin-bottom: 40px;
  }

  .price.colored {
    color: #63D6AF;
  }

  .typo-button {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 400;
    width: 237px;
    font-family: "AvenirNext-DemiBold", 'Helvetica Neue', sans-serif;
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin: auto;
  }

  .big {
    font-weight: bold;
    font-size: 33.53px;
    font-family: "Montserrat-Bold", 'Helvetica Neue', sans-serif;
    margin-right: 10px;
  }

  .header {
    padding: 22px 30px;
    border-bottom: 2px solid $borderColor;
  }

  .content {
    padding: 20px;
  }

  .features {

    .feature-item {

      margin-bottom: 14px;

      .feature-name {
        margin-left: 12px;
      }
    }
  }

  .recommended {

    position: absolute;
    top: -24px;
    left: 30px;
    right: 30px;

    background: #63D6AF;
    padding: 10px 0px;
    border-radius: 4px;
    overflow: hidden;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 22px;
    font-size: 12px;
  }

  .round {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid $green;
    border-radius: 50%;
    font-size: 12px;
    color: $green;
  }

  .choose-btn {
    margin-top: 30px;
    background: #fff;
    padding: 14px 32px;
    border-radius: 4px;
    overflow: hidden;
    color: $dark;
    border: 1px solid $dark;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;

    &.selected, &:hover {
      background: $green;
      border: 1px solid $green;
      color: #fff;
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 479px) {

  .price-wrapper {
    margin: 65px !important;
  }

  .big {
    font-size: 21px !important;
    font-weight: 700;
  }
  .typo-button {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 900px) {

  .price-wrapper {
    margin: 65px !important;
  }

  .big {
    font-size: 21px !important;
    font-weight: 700;
  }
  .typo-button {
    font-size: 12px !important;
  }
}