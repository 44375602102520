$dark: #545454;
$light: #5B6B82;
$lightGrey: #f8f9fa;

// $gradientStart: #f0cf32;
// $gradientEnd: #E73587;
// $gradientStart: #fff248;
// $gradientEnd: #fff248;

$blueGradient:  linear-gradient(214deg, #09133C, #205167);
$blueGradientDown:  linear-gradient(0, #09133C -100%, #205167 100%);
$blueGradientUp:  linear-gradient(0, #0b5287 10%, #051635 87%);
$darkerGradient:  linear-gradient(214deg, #000000, #322f35);
$rdarkerGradient:  linear-gradient(214deg, #000000, #322f35);
$darkGradient:  linear-gradient(214deg, #594C65, #322f35);
$darkGradientTransparent:  linear-gradient(214deg, #000000D5, #322f35D5);
$blueGradientTransparent: linear-gradient(214deg, #1c3460, #0F2A51E8);
// $blueGradientTransparent: linear-gradient(214deg, #0d1d42, #0F2A51E8);
$purpleBlue: linear-gradient(90.28deg, #1D465E 30%, #0D173E 80%);
$purpleBlueCorner: linear-gradient(251deg, #09133C, #2C737F);
$purpleBlueDown: linear-gradient(180deg, #1D465E 30%, #0D173E 80%);
// $purpleBlue : #20253a;
$purpleBlueLight : #3E4668;

$yellowGradient: linear-gradient(90deg, #BDBB9B, #FFF248);
$tealGradient: $yellowGradient;
// $tealGradient: linear-gradient(90deg, #BDBB9B, #2DFCC9);

$purple: #331C60;
// $teal: #1DCBD1;
$yellow: #fff248;
$teal: $yellow;
// $rteal: #2dfcc9;
$rteal: #04716b;
$blue: #0F2A51;
$green: #26AB81;
$gradientStart: $yellow;
$gradientEnd: $yellow;
// $yellow: #2DFCC9;
// $yellow: #F1C35E;
$orange: #FFB34B;
$red: #d47;