@import "../../theme/colors";

$spacing: 40px;

.register-screen {
  // background: url("../../images/bg_signup.png"),
  //   linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(50, 47, 53, 1));
  background     : $blueGradient;
  background-size: cover;
  min-height     : 100vh;

  .background {
    // background: $darkGradient;
  }

  .top-padding {
    padding-top: 10vh;
  }

  .link {
    cursor         : pointer;
    text-decoration: underline;
  }

  .page-title {
    color        : #fff;
    margin-bottom: 30px;
  }

  .terms {
    font-size: 14px;
    padding  : 20px;
  }


  .error-box {
    border       : 1px solid $red;
    border-radius: 4px;
    background   : rgba(255, 255, 255, 0.14);

    margin-left : $spacing;
    margin-right: $spacing;
    padding     : 20px;

    .error {
      font-weight: bold;
    }
  }

  .info-form {}

  .logo {
    cursor: pointer;
  }
}

@media screen and (max-width: 750px) {
  .terms {
    font-size: 12px !important;
    padding  : 0 !important;
  }
}