.bonus-wrapper {
  width: 50%;
  margin: auto;
  padding: 20px 20px;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
  // margin-top: 15px;
  margin-bottom: 15px;

  .opt-logo {

    margin-right: 10px;

    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

    img {
      width: 80px;
      height: 60px;
      object-fit: contain;
    }
  }

  .opt-name {
    width: 120px;
    font-weight: bold;
    margin-bottom: 4px;
    font-size: .8rem;
  }

  .opt-desc {

    font-size: 13px;
    margin-right: 10px;
  }

  .go-btn {
    width: 115px;
    font-size: 12px;
    margin-left: 0;
    padding: 0;
    min-height: 25px;
    height: 35px;
  }

  @media screen and (max-width: 750px) {
    width: 100%;

    .go-btn {
      width: 100px;
      font-size: 12px;
      margin-left: 0;
      padding: 0;
    }

    .opt-name {
      width: unset;
    }

    .opt-desc {
      display: none;
    }
  }
}