@import "../../../theme/variables";
@import "../../../theme/colors";

.main-logged {
  .hamburger {
    // position: fixed;
    position: absolute !important;
    top     : 30px;
    left    : 35px;
    display : none;

    font-size: 26px;
    color    : #fff;

    justify-content    : center;
    align-items        : center;
    cursor             : pointer;
    // background-color: rgba(0, 0, 0, 0.3);

    // -webkit-box-shadow: -1px 0px 11px 0px rgba(0, 0, 0, 0.18);
    // -moz-box-shadow: -1px 0px 11px 0px rgba(0, 0, 0, 0.18);
    // box-shadow: -1px 0px 11px 0px rgba(0, 0, 0, 1);
    transform        : scaleX(-1);
    -webkit-transform: scaleX(-1);
    -moz-transform   : scaleX(-1);
    z-index          : 999; // important

    .fa-list {
      width : 35px;
      height: 35px;
    }
  }

  .main-side-bar {
    z-index: 1000; // important

    position: fixed;
    left    : 0;
    top     : 0;
    bottom  : 0;

    width        : $sideBarWidth;
    background   : $purpleBlue;
    background   : linear-gradient(28.28deg, #1D465E 30%, #0D173E 50%);
    // background: linear-gradient(211.6deg, #26AB81 -81.34%, #3D175F 91.29%);

    .main-logo {
      width : 100px;
      margin: 20px;
    }

    .mini-sep {
      margin-left: 10px;
      display    : inline-block;
    }

    .link {
      padding        : 16px 20px;
      display        : block;
      text-decoration: none;
      color          : #fff;
      border-left    : 2px solid transparent;
      margin-left    : 2px;
      cursor: pointer;
      font-size: 14px;
      transition: all .2s ease-in-out;
    }

    .link-active, .link:hover {
      // border-left: 2px solid #ddd;
      // background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))
      background : linear-gradient(90deg, #ffffff14, #00000001);
      border-left: 3px solid #ffffff60;
    }


    .close {
      position: fixed;
      top     : 22px;
      right   : 22px;
      display : none;

      font-size: 26px;
      color    : #fff;

      justify-content: center;
      align-items    : center;
      cursor         : pointer;
    }

    .bonus {
      margin-top : 6vh;
      margin-left: 15px;
    }

    .logout {
      position: absolute;
      bottom  : 20px;
      left    : 0;
      right   : 0;
    }

    &.shown {
      width        : auto;
      right        : 0;
      // background: linear-gradient(128.28deg, #26ab81 -36.35%, #3d175f 90.73%);
      background: $blueGradientTransparent;
      display      : block;

      .menu {
        flex-direction: column;
        padding-top   : 15px;
        padding-bottom: 30px;

        .main-logo {
          width: 120px;
        }

        .link {
          font-weight: bold;
          font-size  : 3vh;
        }
      }

      .hamburger {
        display: flex;
      }

      .close {
        display: flex;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .main-side-bar {
      display: none;
    }

    .hamburger {
      display: flex;
    }
  }
}