@import "../../../theme/colors";

.logged-btn {
  z-index: 3;

  text-align: center;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;

  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  border: 1px solid #fff;

  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;

  cursor: pointer;

  &.disabled {
    opacity: 0.4;
  }

  &.cyan-btn {
    background: $yellow;
    //  linear-gradient(126.12deg, #21F3DC -21.41%, #B77BFF 100%);
    border-radius: 6px;
    color: black;
  }
  &.small {
    width: 240px !important;
    padding: 20px;
    margin: 20px 10px !important;
  }
  &.centered{
    margin: auto;
  }
  

  &:hover {
    // opacity: 0.8;
    cursor : pointer;
    scale: 1.05;
  }
}

