@import "../../../theme/colors";

.bg-color {
  background: rgba(0, 0, 0, 0)
    linear-gradient(270deg, rgb(89, 76, 101) -100%, rgb(50, 47, 53) 100%) repeat
    scroll 0% 0%;
  width: 67%;
  margin: 16px;
  border-radius: 5px;
}

.old-prono {
  opacity: 0.5;
}

.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.prono-wrapper {
  height: auto;
  transition: height 1s ease-in;

  .prono-details {
    .close {
      align-self: flex-end;
      margin-right: 45px;
      margin-top: 0px;
      opacity: 0.75;
      cursor: pointer;
    }

    .cta-bonus {
      justify-content: center;
      margin-top: 40px;

      .main-cta {
        width: 350px;
      }
    }

    padding-top: 40px;
    transition: height 0.2s ease-in-out;
    height: auto;
    width: 100%;
    // display: flex;
    // flex-direction: column;
    background: $purpleBlueDown;

    .vSep {
      min-height: 250px;
      height: 100%;
      width: 2px;
      background: #8b8a8b40;
    }

    .second-row {
      height: 380px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .prono-box {
      width: 50%;
      border-bottom: 3px solid #8b8a8b40;
      min-height: 250px;
      height: 100%;
      display: flex;
      justify-content: center;

      // width: 100%;
      // flex-direction: row;
      .prono-box-content {
        text-align: center;
        padding: 3% 10% 3% 10%;
        width: 100%;

        .chartBoxLine {
          // width: 50%;
          text-align: center;
          display: flex;
          flex-direction: column;
          min-height: 120px;
        }

        .title {
          text-transform: uppercase;
        }
      }
    }
  }

  // background: linear-gradient(90.28deg, #1D465E 30%, #0D173E 80%);
  // background: url("../../../images/bg_prono.png") no-repeat, $purpleBlue;
  background-size: 100%;
  background: $purpleBlue;
  margin-bottom: 1.6em;
  max-width: 92%;
  min-height: 478px;
  border-radius: 1px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-evenly;
  margin-bottom: 20px;
  margin-left: 50px;
  // padding: 30px 0 0 0;
  justify-content: flex-start;

  .prono-content {
    background: url("../../../images/bg_prono.png") no-repeat, $purpleBlue;
    background-size: cover;
    background-position: center;
    padding-bottom: 50px;
    justify-content: space-between;
    height: 478px;
    display: flex;
    flex-direction: column;
    align-content: center;
    min-width: 100%;
    align-items: center;
    // margin-bottom: 1.6em;

    .col-center {
      height: 100px;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      align-content: center;
      justify-items: legacy;
    }

    .prono-footer {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      justify-items: center;
      text-align: center;
      align-items: center;
      width: 90%;
    }

    .date {
      width: 70%;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      opacity: 0.58;
    }

    .title {
      max-width: 90%;
      min-width: 70%;
      .logo {
        min-width: 73px;
        height: 73px;
      }

      .clubs {
        font-size: 34px;
      }

      text-align: center;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
    }

    .league {
      font-size: 19px;
      font-weight: 400px;
      font-family: "AvenirNext-DemiBold", "Helvetica Neue", sans-serif;
    }

    .prediction-frame {
      width: 80%;
      height: 30%;
      // background: linear-gradient(180deg, #303752, #1c2030);
      background: $purpleBlue;
      // border: solid 1px #fff248;
      border: solid 1px $rteal;
      padding: 12px 36px 12px 36px;

      .prediction {
        background: $purpleBlue;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        font-size: 18px;

        .predicted-team {
          width: 48%;
          // color         : $rteal;
          color: white;
          font-size: 20px;
          text-transform: uppercase;
        }
      }

      .cote {
        // margin-left: auto;
        // margin-right: auto;
        display: flex;
        width: 40%;
        margin: auto;
        justify-content: center;
        justify-items: center;
        align-items: center;
        font-size: 19px;
      }
    }
  }

  .league-logo {
    max-height: 84px;
    width: 55px;
    max-width: 55px;
    margin-top: 40px;
    margin-right: 40px;
    // align-self: flex-end;
  }

  .bookmakers-header {
    height: 120px;
    margin-top: 50px;
    background: $purpleBlue;
    padding: 40px 120px;
    font-size: 36px;
    justify-content: space-evenly;

    .bm-title {
      width: 30%;
      cursor: pointer;
    }

    .onentwo {
      font-size: 17px;
      width: 45%;
      justify-content: space-between;
      text-align: center;
      color: #c1c1c1;

      .up {
        font-size: 30px;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .prono-wrapper {
    max-width: 95%;
    min-height: unset;
    margin: auto auto 5px auto;

    .prono-content {
      padding-bottom: unset;
      // justify-content: space-between;
      height: 280px;

      .col-center {
        height: unset;
      }

      .league-logo {
        max-height: 21px;
        width: 20px;
        margin-top: 10px;
        margin-right: 10px;
      }

      .title {
        .logo {
          min-width: unset;
          height: 25px;
          margin-top: 0;
        }

        .clubs {
          font-size: 13px;
          margin-left: 0px;
        }
      }

      .date {
        font-size: 9px;
      }

      .league {
        font-size: 9px;
      }

      .prediction-frame {
        border-radius: 2px;
        height: 40%;
        flex-direction: column;
        padding: 12px !important;
        align-items: center;

        .prediction {
          font-size: 8px;
          padding: 0 10px;
          width: 90% !important;
          justify-content: space-evenly !important;

          div {
            width: 50%;
            text-align: center;
          }

          .predicted-team {
            font-size: 8px;
            width: 50% !important;
          }
        }

        .cote {
          width: 47%;
          font-size: 8px !important;
          margin-top: 7px;
          justify-content: center !important;

          .val {
            font-size: 10px !important;
          }

          .small {
            width: 135px !important;
            height: 24px !important;
            min-height: unset !important;

            .button-title {
              font-size: 8px;
            }
          }
        }

        padding: 12px 0px 12px 12px;
      }
    }

    .prono-details {
      padding-top: unset;
      height: unset;
      padding-bottom: 40px;

      .close {
        margin-right: 15px;
        margin-top: 10px;
      }

      .prono-box {
        width: 100% !important;
        border-bottom: 3px solid #8b8a8b40;
        min-height: unset;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 100%;
        display: flex;
        justify-content: center;

        // width: 100%;
        // flex-direction: row;
        .prono-box-content {
          text-align: center;
          padding: 0 !important;
          width: 100%;

          .chartBoxLine {
            min-height: unset;
          }

          .title {
            font-size: 10px;
          }
        }
      }
    }

    .bookmakers-header {
      height: 80px;
      padding: 40px 0px;
      font-size: 16px;
      justify-content: space-around;

      .onentwo {
        font-size: 9px;

        .up {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .prono-content {
    height: 230px !important;
  }
}
