@import "../../../theme/variables";
@import "../../../theme/colors";
.main-mobile-bar {
  display: none;
}
@media screen and (max-width: 600px) {
  .main-logged {
    display: block;

    .hamburger {
      // position: fixed;
      position: absolute !important;
      top: 30px;
      left: 35px;
      display: none;

      font-size: 26px;
      color: #fff;

      justify-content: center;
      align-items: center;
      cursor: pointer;
      // background-color: rgba(0, 0, 0, 0.3);

      // -webkit-box-shadow: -1px 0px 11px 0px rgba(0, 0, 0, 0.18);
      // -moz-box-shadow: -1px 0px 11px 0px rgba(0, 0, 0, 0.18);
      // box-shadow: -1px 0px 11px 0px rgba(0, 0, 0, 1);
      transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      z-index: 999; // important

      .fa-list {
        width: 35px;
        height: 35px;
      }
    }

    .main-mobile-bar {
      z-index: 1000; // important

      width: 100%;
      padding: 0 10px;
      // right        : 0;
      // background: linear-gradient(128.28deg, #26ab81 -36.35%, #3d175f 90.73%);
      background: $blueGradient;
      display: block;
      height: 65px;
      position: fixed;
      bottom: 0;

      background: $blueGradient;
      // background   : linear-gradient(28.28deg, #1D465E 30%, #0D173E 50%);
      // background: linear-gradient(211.6deg, #26AB81 -81.34%, #3D175F 91.29%);

      .main-logo {
        width: 100px;
        margin: 20px;
      }

      .mini-sep {
        // margin-left: 10px;
        text-align: center;
        display: inline-block;
        position: static;
        z-index: 5;
      }

      .link {
        // padding        : 16px 20px;
        display: flex;
        flex-direction: column;
        width: 64px;
        text-decoration: none;
        color: #fff;
        border-left: 2px solid transparent;
        // margin-left    : 2px;
        margin: 5px;
        z-index: 1000;

        font-size: 14px;
        .fa {
          text-align: center;
          margin-bottom: 5px;
        }
        .dot {
          height: 64px;
          width: 64px;
          background-color: $teal;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          filter: drop-shadow(0px 3px 3px #000);
          margin-bottom: 3px;
          img {
            width: 95%;
            height: 84%;
          }
        }
      }

      .prediction {
        transform: translate(0, -40px);
        bottom: 5px;
      }

      .link-active {
        // border-left: 2px solid #ddd;
        // background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))
        // background: linear-gradient(90deg, #ffffff14, #00000001);
        // border-left: 3px solid #ffffff60;
      }

      .close {
        position: fixed;
        top: 22px;
        right: 22px;
        display: none;

        font-size: 26px;
        color: #fff;

        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .bonus {
        margin-top: 6vh;
        margin-left: 15px;
      }

      .logout {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
      }

      &.shown {
        .menu {
          display: flex;
          flex-direction: row;
          padding-top: 15px;
          padding-bottom: 30px;
          justify-content: space-around;
          .main-logo {
            width: 120px;
          }

          .link {
            font-weight: bold;
            font-size: 9px;
          }
        }

        .hamburger {
          display: flex;
        }

        .close {
          display: flex;
        }
      }
    }
  }
}
