.page404 {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(214deg, #09133c, #205167);
    color: white;
    padding: 0 15%;
    text-align: center;

    & p {
        font-size: 1.5em;
    }
}

.img404 {
    font-size: 5em;
}

.btn404 {
    height: 40px;
    width: 250px;
    background: transparent;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
        background: white;
        color: #09133c
    }
}

@media screen and (max-width: 750px){
    .page404{
        font-size: 10px;
    }
}