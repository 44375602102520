@import "../../../theme/colors";

.bookmaker {
  // justify-content: space-between;
  // .col{
  //     min-width: 20%;
  // }
  height: 120px;
  margin-top: 30px;
  padding: 40px 90px;
  font-size: 36px;
  justify-content: space-evenly;

  .logo {
    max-width: 100%;
  }

  .bm-title {
    width: 30%;
    cursor: pointer;
  }

  .onentwo {
    // font-size: 17px;
    width: 40%;
    justify-content: space-between;
    text-align: center;
    // color: #c1c1c1;
    font-size: 30px;
    color: #fff;
  }

  @media screen and (max-width: 1000px) {
    height: unset;
    padding: 0px 0px;
    font-size: 12px;
    .onentwo {
      font-size: 16px;
    }
    .logo {
      margin-top: 0;
    }
  }
}
