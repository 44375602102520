@import "/../../theme/colors";


.terms-screen {

  .terms-title {
    padding: 60px 0px;

    @media screen and (max-width: 530px) {
      padding: 80px 0px;
    }

    text-align: center;
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    background: $blueGradientTransparent;
  }

  .options {

    margin: 3vw 3vw;

  }

}